import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { API } from '../../api'
import { CopyIcon, DeviceIcon, HomeIcon, PlusIcon2 } from '../../Assets/AllSvg'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { localStorageGet } from '../../utils/localstorage'
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faRemove, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next'
import BarLoader from 'react-spinners/BarLoader';

const FloorDashboard = () => {
    const [ form, setForm ] = useState('')
    const [ dashboard, setDashboard ] = useState([])
    const [ showDevice, setShowDevice ] = useState(false)
    const [ dashboardData, setDashboardData ] = useState('')
    const [ userPermisiions, setUserPermisiions ] = useState([])
    const [ open, setOpen ] = useState(false)
    const [ openCopy, setOpenCopy ] = useState(false)
    const [ copyData, setCopyData ] = useState('')
    const [ flrData, setFlrData ] = useState([])
    const [ show, setShow ] = useState(false)
    const [ selectValue, setSelectValue ] = useState( 'Select Floor' )
    const [ sendCopy, setSendCopy ] = useState('')
    const [ loading, setLoading ] = useState(true)
    const { t } = useTranslation()
    const URL =  process.env.REACT_APP_BASE_URL

    const userType = localStorageGet('type')
    // const loginUserId = localStorageGet('user_id')

    const navigate = useNavigate()
    const cancelButtonRef = useRef(null)

    const ID = location.pathname.split('/').pop()

    const getSiteData = async () => {
        if(ID){
            console.log('getSiteData floorDashboard')
            setShowDevice(false)
            const response = await API(`/api/site/id/${ ID }`)
            setForm(response.site)

            // const dashboard = await API('/api/lists/dashboard')
            const dashboard = await API('/api/lists/dashboard/'+ID)
            const dashboardList = dashboard.dashboard.filter((val) => val.siteId === ID)
            const filterValue = dashboardList.filter((val) => val.isDelete === false)
            setDashboard(filterValue)
            setLoading(false)
            if(userType !== 'super-admin') {
                const permissions = localStorageGet('permissions')
                setUserPermisiions(JSON.parse(permissions))
            }
        }else{
            setForm('')
        }
    }

    const deleteDashboard = async () => {
        const data = {
            method: 'PATCH',
            data:{
                ...dashboardData,
                isDelete:true
            }
        }
        const response = await API(`/api/update/dashboard/${ dashboardData._id }`, data)

        if(response.success){
            getSiteData()
            setOpen(false)
            toast.error('Dashboard Succesfuuly Deleted', { autoClose: 5000 })
        }
    }

    const setFloordata = async (val, e) => {
        e.stopPropagation();
        setOpenCopy(true);
        setCopyData(val)
        setSelectValue('Select Floor');

        const response = await API('/api/site/lists')
        if(userType === 'super-admin'){

            setFlrData(response.sites)
        }else{
            const sitesData = JSON.parse(localStorageGet('sites'))
            const siteslist = response.sites.filter((val) => sitesData.includes(val.name))
            setFlrData(siteslist)
        }
    }

    const handleCopyDashboard = (val, floor) => {
        setSelectValue(floor.name);
        setShow(false)

        setSendCopy({
            siteId: val._id,
            floorId: floor._id
        })
    }

    const onclickCopyDashboard = async () => {
        const data = {
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: {
                name: copyData.name,
                email: copyData.email,
                cards: copyData.cards,
                siteId: sendCopy.siteId,
                floorId: sendCopy.floorId,
                image:copyData.image
            }
        }
        const response = await API('/api/add/dashboard', data)
        if(response.success){
            toast.success('Dashboard copied successfully', { autoClose: 5000 })
            navigate(`/site/${ sendCopy.siteId }`)
            setOpenCopy(false);
            getSiteData()
        }
    }

    useEffect(() => {
        getSiteData()
    }, [ID])

    return (
        <>
            <div>
                <div className='flex sm:flex-row flex-col items-center gap-4 mb-[-20px]'>
                    <button onClick={ (e)=>{ window.history.go(-1); return false; } } className='w-fit py-[4px] px-[20px] border-[2px] border-[#599bfb] text-[#599bfb] transition-all font-semibold rounded-md flex justify-end'>{t('back')}</button>
                    <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">
                        {form.name}
                    </h3>
                    <div className='flex gap-4'>
                        {userType === 'super-admin' ? <Link to={ `/site/addfloor?id:${ ID }` } className='w-fit py-[4px] px-[20px] border-[2px] bg-[#5a7bad] transition-all text-white font-semibold rounded-md flex justify-end'>{t('Add_Floor')}</Link>
                            : userPermisiions.includes('Add Floor') && <Link to={ `/site/addfloor?id:${ ID }` } className='w-fit py-[4px] px-[20px] border-[2px] bg-[#5a7bad] transition-all text-white font-semibold rounded-md flex justify-end'>{t('Add_Floor')}</Link>
                        }
                        {userType === 'super-admin' ? <Link to="/device/add" className='w-fit py-[4px] px-[20px] border-[2px] bg-[#599bfb] transition-all text-white font-semibold rounded-md flex justify-end'>{t('Add_device')}</Link>
                            : `${ userPermisiions.includes('Add Device') }` && <Link to="/device/add" className='w-fit py-[4px] px-[20px] border-[2px] bg-[#599bfb] transition-all text-white font-semibold rounded-md flex justify-end'>{t('Add_device')}</Link>
                        }
                    </div>
                </div>
                <div className="mt-6">
                    {form.floors?.map((val) =>
                        <div key={ val._id } className="py-[35px] border-b-[1px] border-[#cecece]">
                            <p className='uppercase text-xl leading-6 font-bold text-[#5a7bad] '>{val.name}</p>
                            {userType === 'super-admin' && <div>

                                <div className='bg-[#f6fbff] p-3 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl flex justify-between items-center text-[18px] font-bold text-[#5a7bad] w-[25%] my-[20px]' onClick={ () => setShowDevice(!showDevice) }>{t('Devices')} : {val.devices.length}
                                    {!showDevice && <FontAwesomeIcon
                                        size='lg'
                                        className='right-0'
                                        icon={ faChevronUp }
                                    />}
                                    {showDevice && <FontAwesomeIcon
                                        size='lg'
                                        className='right-0'
                                        icon={ faChevronDown }
                                    />}
                                </div>
                            </div>}
                            {showDevice && userType === 'super-admin' && <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4'>
                                {val.devices?.map((device) =>
                                    <div key={ device._id } className="bg-[#f6fbff] p-3 w-full shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl flex justify-between items-center">
                                        <div>
                                            <p className='text-[#5a7bad]'> <span className='font-bold'>{device.name}</span> - {device.iotHost}</p>
                                        </div>
                                        <div>
                                            <span className='text-[#599bfb] mr-3 font-bold text-[17px]'>{device.iotId}</span> <DeviceIcon/>
                                        </div>
                                    </div>
                                )}
                            </div>}
                            <div className='mt-[30px]'>
                                <div className='block text-md font-medium text-[#5a7bad] mt-4 mb-2'>
                                    {userType === 'super-admin' ?
                                        <div data-tooltip-id="add_new_dashboard" data-tooltip-variant="info" onClick={ () => navigate(`/dashboard/new?site_id=${ ID }&floor_id=${ val._id }`) } className="flex flex-row h-full w-min gap-2 items-center">
                                            <h2 className='text-[#599bfb]'>{t('Dashboard')}</h2>
                                            <PlusIcon2/>
                                        </div>
                                        : userPermisiions.includes('Add Dashboard') ?
                                            <div data-tooltip-id="add_new_dashboard" data-tooltip-variant="info" onClick={ () => navigate(`/dashboard/new?site_id=${ ID }&floor_id=${ val._id }`) } className="flex flex-row h-full w-min gap-2 items-center">
                                                <h2 className='text-[#599bfb]'>{t('Dashboard')}</h2>
                                                <PlusIcon2/>
                                            </div>
                                            :
                                            <div data-tooltip-id="add_new_dashboard" data-tooltip-variant="info" className="flex flex-row h-full w-min gap-2 items-center">
                                                <h2 className='text-[#599bfb]'>{t('Dashboard')}</h2>
                                            </div>
                                    }
                                </div>
                                {loading && <div className="flex justify-center items-center">
                                    <BarLoader color={ '#5a7bad' } loading={ loading } size={ 150 } />
                                </div>}
                                {!loading && <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                    {dashboard.map((db) =>
                                        db.floorId === val._id &&
                                            <div onClick={ () => {
                                                if(userType === 'super-admin'){
                                                    navigate(`/dashboard/card?dashboard_id=${ db._id }`)
                                                }else{
                                                    userPermisiions.includes('View Chart') && navigate(`/dashboard/card?dashboard_id=${ db._id }`)
                                                }
                                            } }
                                            key={ db._id }
                                            className={ (''+db?.image) == 'undefined' ? 'group relative flex flex-row justify-between bg-[#f6fbff] w-full p-5 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl' : 'group flex flex-row justify-between relative bg-[#f6fbff] w-full p-5 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl' }>

                                                <div className='flex gap-[15px] items-center'>
                                                    <div className={ (''+db?.image) == 'undefined' ? 'bg-[#599bfb] p-4 rounded-lg' : 'rounded-lg' }>
                                                        {(''+db?.image) == 'undefined' && <HomeIcon />}
                                                        {(''+db.image) !== 'undefined' && <img src={ URL+db.image } className="h-[56px] w-[56px]" alt="" />}
                                                    </div>
                                                    <div>
                                                        <h5 className='text-[#5a7bad] font-bold'>{db.name}</h5>
                                                    </div>
                                                </div>
                                                <div className='group-hover:block absolute right-3 top-3 transition-all hidden items-center'>
                                                    <div className='flex gap-4 items-center'>
                                                        {userType === 'super-admin' && <button onClick={ (e) => setFloordata(db, e) }
                                                            data-tooltip-id="copy_dashboard"
                                                            data-tooltip-variant="info"
                                                            className='text-[#fff] text-[14px] rounded-md py-[1px] px-[10px] bg-[#599bfb] font-medium'>{t('Copy')}</button>}
                                                        {userType === 'super-admin' ? <FontAwesomeIcon
                                                            size='lg'
                                                            data-tooltip-id="edit_dashboard"
                                                            data-tooltip-variant="success"
                                                            onClick={ (e) => { e.stopPropagation(); navigate(`/dashboard/new?site_id=${ ID }&floor_id=${ val._id }&dashboard_id=${ db._id }`) } }
                                                            icon={ faEdit }
                                                        /> : userPermisiions.includes('Edit Dashboard') && <FontAwesomeIcon
                                                            size='lg'
                                                            data-tooltip-id="edit_dashboard"
                                                            data-tooltip-variant="success"
                                                            onClick={ (e) => { e.stopPropagation(); navigate(`/dashboard/new?site_id=${ ID }&floor_id=${ val._id }&dashboard_id=${ db._id }`) } }
                                                            icon={ faEdit }
                                                        />}
                                                        {userType === 'super-admin' ?
                                                            <FontAwesomeIcon
                                                                size='lg'
                                                                data-tooltip-id="delete_dashboard"
                                                                data-tooltip-variant="warning"
                                                                onClick={ (e) => { e.stopPropagation(); setOpen(true); setDashboardData(db) } }
                                                                className='w-fit py-[3px] transition-all text-[#ff6262] font-semibold rounded-md flex justify-end'
                                                                icon={ faRemove }
                                                            />
                                                            :userPermisiions.includes('Delete Dashboard') &&  <FontAwesomeIcon
                                                                size='lg'
                                                                data-tooltip-id="delete_dashboard"
                                                                data-tooltip-variant="warning"
                                                                onClick={ (e) => { e.stopPropagation(); setOpen(true); setDashboardData(db) } }
                                                                className='w-fit py-[3px] transition-all text-[#ff6262] font-semibold rounded-md flex justify-end'
                                                                icon={ faRemove }
                                                            />}
                                                    </div>
                                                </div>
                                            </div>
                                    )}
                                </div>}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Transition.Root show={ open } as={ Fragment }>
                <Dialog as="div" className="relative z-10" initialFocus={ cancelButtonRef } onClose={ setOpen }>
                    <Transition.Child
                        as={ Fragment }
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={ Fragment }
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                    {t('Delete')} {dashboardData.name}
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        {t('delete_dashboard_des')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex justify-end sm:px-6">
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[transparent] focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={ () => setOpen(false) }
                                            ref={ cancelButtonRef }
                                        >
                                            {t('Cancel')}
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={ deleteDashboard }
                                        >
                                            {t('Delete')}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <Transition.Root show={ openCopy } as={ Fragment }>
                <Dialog as="div" className="relative z-10" onClose={ () => setOpenCopy(false) }>
                    <Transition.Child
                        as={ Fragment }
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-[#292929] bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                            <Transition.Child
                                as={ Fragment }
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                                    <div>
                                        <div className='flex gap-[15px]'>
                                            <div className='bg-[#599bfb] h-fit p-3 rounded-lg'>
                                                <CopyIcon />
                                            </div>
                                            <div>
                                                <h5 className='text-[#599bfb] font-medium text-xl'>{t('Copy')} {copyData.name}</h5>
                                                <h5 className='text-[#5a7bad] font-normal'>{t('copy_dashbord')}</h5>
                                                <div className='w-full relative mt-2'>
                                                    <label htmlFor="User Type" className="block text-sm font-medium text-[#5a7bad]">
                                                        {t('Floor')}
                                                    </label>
                                                    <div className='relative group mt-1'>
                                                        <input value={ selectValue } onClick={ ()=>setShow(!show) } type="text" className=" block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] text-[#5a7bad] sm:text-sm" />
                                                        {show &&
                                                        <div className='absolute top-[110%] bg-white py-[5px] border-gray-300 border-[1px] rounded-lg w-full'>
                                                            {flrData && flrData.map((val, i) => <div key={ i }>
                                                                <div className='py-[5px] font-bold text-sm px-[20px] text-[#599bfb] font-medium cursor-pointer hover:bg-slate-100'># {val.name}</div>
                                                                {val.floors?.map((floor) =>
                                                                    <div key={ floor._id } onClick={ ()=> handleCopyDashboard(val, floor) } className='py-[5px] px-[20px] pl-[35px] text-sm text-[#5a7bad] font-medium cursor-pointer hover:bg-slate-100'>{floor.name}</div>
                                                                )}
                                                            </div>
                                                            )}
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex justify-end gap-3 mt-6'>
                                            <button onClick={ ()=>setOpenCopy(false) } className='px-[15px] py-[5px] font-medium text-[#5a7bad] rounded-lg border-[1px] border-[#5a7bad]'>{t('Cancel')}</button>
                                            <button onClick={ ()=> onclickCopyDashboard() } className='px-[15px] py-[5px] bg-[#5a7bad] font-medium text-[#fff] rounded-lg border-[1px] border-[#5a7bad]'>{t('Copy')}</button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <Tooltip
                id="add_new_dashboard"
                place="top"
                content={ t('Add_new_Dashboard') }
            />
            <Tooltip
                id="delete_dashboard"
                place="top"
                content={ t('Delete_dashboard') }
            />
            <Tooltip
                id="edit_dashboard"
                place="top"
                content={ t('Edit_dashboard') }
            />
            <Tooltip
                id="copy_dashboard"
                place="top"
                content={ t('Copy_dashboard') }
            />
        </>
    )
}

export default FloorDashboard