import React, { useState, Fragment, useEffect } from 'react';
import { Listbox, Switch, Transition, Dialog } from '@headlessui/react';
import { FaSortDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { API } from '../../api'
import { toast } from 'react-toastify'
import { localStorageGet } from '../../utils/localstorage'
import { CopyIcon } from '../../Assets/AllSvg'
import { io } from 'socket.io-client'
import JSONPretty from 'react-json-pretty';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
const socket = io('wss://system.innovura.app:3000/')
socket.connect()

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const GenerateReport = () => {

    const [ open1, setOpen1 ] = useState(false)
    const [ selectValue, setSelectValue ] = useState({ name: 'Select Dashboard' })
    const [ dbData, setDbData ] = useState([])
    const [ show, setShow ] = useState(false)
    const [ copyChart, setCopyChart ] = useState('')
    const userType = localStorageGet('type')

    const [ editedRow, setEditedRow ] = useState(null);
    const [ editedQotRow, setEditedQotRow ] = useState(null);
    const [ selectedQotId, setSelectedQotId ] = useState(null);
    const [ selected, setSelected ] = useState('Select IP');
    const [ selectedSite, setSelectedSite ] = useState('Select Site');
    const [ meterTypes, setMeterTypes ] = useState([])
    const [ selectedMeter, setSelectedMeter ] = useState('');
    const [ ipAddress, setIpAddress ] = useState('192.168.0.');
    const [ qotName, setQotName ] = useState('');
    const [ inputValue, setInputValue ] = useState('');
    const [ siteName, setSiteName ] = useState('');
    const [ category, setCategory ] = useState('');
    const [ ipList, setIpList ] = useState([]);
    const [ ipFullList, setIpFullList ] = useState([]);
    const [ qotList, setQotList ] = useState([]);
    const [ mqttData, setMqttData ] = useState([]);
    const [ mqttIp, setMqttIp ] = useState('');
    const [ sitesConfig, setSitesConfig ] = useState([]);
    const [ noOfIpToShow, setNoOfIpToShow ] = useState(5);
    const [ startIndexNoOfIpToShow, setStartIndexNoOfIpToShow ] = useState(0);
    const [ endIndexNoOfIpToShow, setEndIndexNoOfIpToShow ] = useState(4);
    const arrayOfSelect = [ 5, 10, 20, 30, 40, 50, 100 ]

    const { t } = useTranslation()

    const [ formDataList, setFormDataList ] = useState([]);
    const [ formSlaveDevice, setFormSlaveDevice ] = useState([]);
    const [ formSiteConfig, setFormSiteConfig ] = useState([]);

    socket.on('connect', () => {
        console.log('connected')
    })

    function handleMqttData(data, topic) {
        if (topic == 'loggerdata/Nano-0-'+(''+mqttIp).split('.')[ 3 ] ) {
            setMqttData(data)
        }
    }

    socket.on('message', (e) => {
        if (e?.topic?.includes('loggerdata/Nano-0-'+(''+mqttIp).split('.')[ 3 ]) ) {
            handleMqttData(e.message, (''+e.topic))
        }
    })

    function compareIPAddresses(a, b) {
        const numA = Number(
            a.ip_address.split('.')
                .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
                .reduce((a, v) => ((a += v), a), 0)
        );
        const numB = Number(
            b.ip_address.split('.')
                .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
                .reduce((a, v) => ((a += v), a), 0)
        );
        return numA - numB;
    }

    const handleSetIpList = (list) => {
        setIpList(list.slice(startIndexNoOfIpToShow, endIndexNoOfIpToShow+1))
    }

    const handleValueChange = (value, field, data) => {
        const index = ipList.findIndex(item => item._id === data._id);
        const updatedIpList = [...ipList];
        updatedIpList[ index ][ field ] = value;
        setIpFullList(updatedIpList);
        handleSetIpList(updatedIpList);
    }

    const handleQotValueChange = (value, field, data) => {
        const index = qotList.findIndex(item => item._id === data._id);
        const updatedIQotList = [...qotList];
        updatedIQotList[ index ][ field ] = value;
        setQotList(updatedIQotList);
    }

    const fetchIPData = async () => {
        try {
            const response = await API('/api/ip/list');
            if(response){
                const list = response.notification.sort(compareIPAddresses)
                setIpFullList(list)
                handleSetIpList(list);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleIncDecNoOfIpToShow = (value) => {
        if (value === 'inc') {
            console.log((endIndexNoOfIpToShow + noOfIpToShow), ipFullList.length-1)
            console.log((ipFullList.length - endIndexNoOfIpToShow), noOfIpToShow)
            if((endIndexNoOfIpToShow + noOfIpToShow) <= (ipFullList.length-1)){
                setStartIndexNoOfIpToShow(startIndexNoOfIpToShow + noOfIpToShow)
                setEndIndexNoOfIpToShow(endIndexNoOfIpToShow + noOfIpToShow)
            }
            else if(ipFullList.length - endIndexNoOfIpToShow < noOfIpToShow){
                setStartIndexNoOfIpToShow(endIndexNoOfIpToShow)
                setEndIndexNoOfIpToShow(ipFullList.length - 1)
            }
        } else if (value === 'dec') {
            if (endIndexNoOfIpToShow === ipFullList.length - 1){
                setStartIndexNoOfIpToShow(startIndexNoOfIpToShow - noOfIpToShow)
                setEndIndexNoOfIpToShow(startIndexNoOfIpToShow)
            }
            else if((startIndexNoOfIpToShow - noOfIpToShow) >= 0){
                setStartIndexNoOfIpToShow(startIndexNoOfIpToShow - noOfIpToShow)
                setEndIndexNoOfIpToShow(endIndexNoOfIpToShow - noOfIpToShow)
            }
        } else if (value === 'initial') {
            setStartIndexNoOfIpToShow(0)
            if ((ipFullList.length-1) < noOfIpToShow){
                setEndIndexNoOfIpToShow((ipFullList.length-1))
            } else{
                setEndIndexNoOfIpToShow(noOfIpToShow-1)
            }
        } else if (value === 'end') {
            setStartIndexNoOfIpToShow(ipFullList.length - noOfIpToShow)
            setEndIndexNoOfIpToShow(ipFullList.length - 1)
        }
    }

    const fetchQotData = async () => {
        try {
            const response = await API('/api/qot/list');
            if(response){
                const l = [...response.notification].sort((a, b) => {
                    if (a.qotName < b.qotName) {
                        return 1;
                    }
                    if (a.qotName > b.qotName) {
                        return -1;
                    }
                    return 0;
                })
                console.log(l)
                setQotList(l);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleAddIP = async () => {
        const data = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: {
                ip_address: ipAddress,
                available: false,
                to_ip: 'Nano-0-159',
                assigned: false,
                usbinstalled: false
            }
        }
        const response = await API('/api/ip/add', data)
        if(response.success){
            toast.success(response.message, { autoClose: 5000 })
            fetchIPData();
        }
    };

    const handleAddQotName = async () => {
        const data = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: {
                qotName: qotName
            }
        }
        console.log(data)
        const response = await API('/api/qot/add', data)
        if(response.success){
            toast.success(response.message, { autoClose: 5000 })
            fetchQotData();
        }
    };

    const DeleteSingleIPs = async(ipdetails) =>{
        const data = {
            method: 'DELETE',
        }
        const response = await API(`/api/ip/delete/${ ipdetails._id }`, data)
        if (response.success) {
            toast.success(response.message, { autoClose: 5000 })
            fetchIPData();
        }
    }

    function confirmDelete (data) {
        confirmAlert({
            title: 'Confirm to Delete?',
            message: '',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteSingleIPs(data)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const DeleteSingleQots = async(qotdetails) =>{
        const data = {
            method: 'DELETE',
        }
        const response = await API(`/api/qot/delete/${ qotdetails._id }`, data)
        if (response.success) {
            toast.success(response.message, { autoClose: 5000 })
            fetchQotData();
        }
    }

    function confirmDeleteQOT (data) {
        confirmAlert({
            title: 'Confirm to Delete?',
            message: '',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteSingleQots(data)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const fetchSlaveDevice = async() => {
        setFormSlaveDevice([]);
        try {
            const APIDATA = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { ip_address: selected }
            };
            const response = await API('/api/deviceslaves/list', APIDATA); // Updated the endpoint URL
            if (response.success) {
                toast.success(response.message, { autoClose: 5000 });
                setFormSlaveDevice(response.notification.slaves)
            }
            else{
                toast.success(response.message, { autoClose: 5000 });
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    const handleUpdateIP = async (data) => {
        setEditedRow(null);
        try {
            const APIDATA = {
                method: 'PUT', // Changed from 'POST' to 'PATCH'
                headers: { 'Content-Type': 'application/json' },
                data: {
                    ip_address: data.ip_address,
                    available: data.available, // Changed from data.handleValueChange to data.available
                    to_ip: data.to_ip,
                    assigned: data.assigned,
                    usbinstalled: data.usbinstalled
                }
            };
            const response = await API(`/api/ip/update/${ data._id }`, APIDATA); // Updated the endpoint URL
            if (response) {
                toast.success(response.message, { autoClose: 5000 });
                fetchIPData();
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    };

    const handleUpdateQot = async (data) => {
        setEditedQotRow(null);
        try {
            const APIDATA = {
                method: 'PUT', // Changed from 'POST' to 'PATCH'
                headers: { 'Content-Type': 'application/json' },
                data: {
                    qotName: data.qotName
                }
            };
            const response = await API(`/api/qot/update/${ data._id }`, APIDATA); // Updated the endpoint URL
            if (response) {
                toast.success(response.message, { autoClose: 5000 });
                fetchQotData();
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    };

    const handleCopyChart = async (db, qotId) => {
        console.log(db)
        setSelectValue(db);
        setSelectedQotId(qotId);
        setShow(false)
    }

    const setDashboardata = async () => {
        setSelectValue({ name: 'Select Dashboard' })

        const dropdownData = []
        const sites = await API('/api/site/lists')
        const dashboard = await API('/api/lists/dashboard')

        if(userType === 'super-admin'){
            sites.sites.map((val) => val.floors.map((flr) => {
                const dboardData = dashboard.dashboard.filter((db) => db.floorId === flr._id && db.siteId === val._id)
                dropdownData.push({ site: `${ val.name } : ${ flr.name }`, id: flr._id, dashboard: dboardData })
            }))
            setDbData(dropdownData)
        }
    }

    const handleUpdateQotAssignedDashaboard = async () => {
        setEditedQotRow(null);
        try {
            const APIDATA = {
                method: 'PUT', // Changed from 'POST' to 'PATCH'
                headers: { 'Content-Type': 'application/json' },
                data: {
                    dashboard_assigned_id: selectValue._id,
                    dashboard_site: selectValue.company,
                    dashboard_name: selectValue.name,
                    iot_host: selectValue.cards[ 0 ].groups[ 0 ].devices[ 0 ].iot_host,
                }
            };
            const response = await API(`/api/qot/update/${ selectedQotId }`, APIDATA); // Updated the endpoint URL
            if (response) {
                toast.success(response.message, { autoClose: 5000 });
                fetchQotData();
                setOpen1(false)

            }
            else{
                setOpen1(false)
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
            setOpen1(false)
        }
    };

    const handleAddField = () => {
        setFormDataList([ ...formDataList, { start_register_address: 0, name: '', unit: '', type_of_value: 'INT32', no_of_decimals: 2, no_of_registers: 2, signed: 'true', function_code: 3, byte_order: 0 }]);
    };

    const handleRemoveField = (index) => {
        const updatedDataList = [...formDataList];
        updatedDataList.splice(index, 1);
        setFormDataList(updatedDataList);
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedDataList = [...formDataList];
        updatedDataList[ index ][ name ] = value;
        setFormDataList(updatedDataList);
    };

    const handleAddFieldSlaveDevices = () => {
        setFormSlaveDevice([ ...formSlaveDevice, { slave_address: '', type: 'CVM-C10', baud_rate: 19200, parity: 'E', stopbits: 1, bytesize: 8, timeout: 0.5 }]);
    };

    const handleRemoveFieldSlaveDevices = (index) => {
        const updatedDataList = [...formSlaveDevice];
        updatedDataList.splice(index, 1);
        setFormSlaveDevice(updatedDataList);
    };

    const handleInputChangeSlaveDevices = (index, event) => {
        let { name, value } = event.target;
        console.log(name, value)
        if (name == 'timeout'){
            value = parseFloat(value)
        }
        const updatedDataList = [...formSlaveDevice];
        console.log(updatedDataList)
        updatedDataList[ index ][ name ] = value;
        setFormSlaveDevice(updatedDataList);
    };

    const AddDeviceSlave = async() => {
        try {
            const APIDATA = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { ip_address: selected, slaves: formSlaveDevice }
            };
            const response = await API('/api/deviceslaves/add', APIDATA); // Updated the endpoint URL
            if (response) {
                toast.success(response.message, { autoClose: 5000 });
                // fetchSlavesData();
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    const fetchMeterTypes = async() =>{
        try{
            const response = await API('/api/metertypes/list')
            if(response.success){
                toast.success(response.message, { autoClose: 5000 })
                setMeterTypes(response.notification)
            }
            else{
                toast.success(response.message, { autoClose: 5000 })
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    const handleMeterDataSave = async() => {
        try {
            const APIDATA = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { meterName: selectedMeter, fields: formDataList }
            };
            const response = await API('/api/metertypes/add', APIDATA); // Updated the endpoint URL
            if (response) {
                toast.success(response.message, { autoClose: 5000 });
                fetchMeterTypes();
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    const addMeterType = async() =>{
        try {
            const data = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    meterName: inputValue,
                    fields: []
                }
            }
            const response = await API('/api/metertypes/add', data)
            if(response.success){
                toast.success(response.message, { autoClose: 5000 })
                fetchMeterTypes()
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    const fetchSiteConfig = async () => {
        try {
            const response = await API('/api/sitesconfig/list');
            if(response){
                setSitesConfig(response.notification);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const addSiteConfig = async(isUpdate = false) =>{
        try {
            const data = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    site: isUpdate ? (sitesConfig.find((e) => e.site === selectedSite)).site : siteName,
                    category: isUpdate ? (sitesConfig.find((e) => e.site === selectedSite)).category : category,
                    config: formSiteConfig
                }
            }
            const response = await API('/api/sitesconfig/add', data)
            if(response.success){
                toast.success(response.message, { autoClose: 5000 })
                fetchSiteConfig()
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    const generateOrDeleteSite = async(generate, deleteInfo = false) =>{
        try {
            const data = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    IdOfSiteConfig: (sitesConfig.find((e) => e.site === selectedSite))._id,
                    deleteInfo
                }
            }
            const response = await API('/api/sitesconfig/'+(generate == true ? 'createSites': 'deleteSites'), data)
            if(response.success){
                toast.success(response.message, { autoClose: 5000 })
            }
            else if(response.success == false){
                toast.error(response.message, { autoClose: 5000 })
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    const fetchSingleSiteConfig = async() =>{
        setFormSiteConfig([]);
        try {
            const APIDATA = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { site: selectedSite }
            };
            const response = await API('/api/sitesconfig/list', APIDATA); // Updated the endpoint URL
            if (response.success) {
                toast.success(response.message, { autoClose: 5000 });
                setFormSiteConfig(response.notification.config)
            }
            else{
                toast.success(response.message, { autoClose: 5000 });
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    const handleInputChangeSitesConfig = (index, event) => {
        let { name, value } = event.target;
        if (name == 'timeout'){
            value = parseFloat(value)
        }
        const updatedDataList = [...formSiteConfig];
        updatedDataList[ index ][ name ] = value;
        setFormSiteConfig(updatedDataList);
    };

    const handleRemoveFieldSiteConfig = (index) => {
        const updatedDataList = [...formSiteConfig];
        updatedDataList.splice(index, 1);
        setFormSiteConfig(updatedDataList);
    };

    const handleAddFieldSiteConfig = () => {
        setFormSiteConfig([ ...formSiteConfig, { ip:'', floor:'', deviceName:'', dashboardName:'', company:'', slaveId:1 }]);
    };

    function confirmDeleteHandleRemoveFieldSlaveDevices (index) {
        confirmAlert({
            title: 'Confirm to Delete?',
            message: '',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleRemoveFieldSlaveDevices(index)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    function confirmDeleteHandleRemoveFieldSiteConfig (index) {
        confirmAlert({
            title: 'Confirm to Delete?',
            message: '',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleRemoveFieldSiteConfig(index)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    function confirmDeleteHandleRemoveField (index) {
        confirmAlert({
            title: 'Confirm to Delete?',
            message: '',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleRemoveField(index)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    useEffect(() => {
        fetchIPData();
        fetchQotData();
        fetchMeterTypes();
        fetchSiteConfig();
        setDashboardata();
    }, []);

    useEffect(() => {
        if (selected !== 'Select IP')
            fetchSlaveDevice()
    }, [selected])

    useEffect(() => {
        if (selected !== 'Select Site')
            fetchSingleSiteConfig()
    }, [selectedSite])

    useEffect(() => {
        if (selectedMeter !== '')
            meterTypes.map((meter, index) => {
                if (meter.meterName === selectedMeter){
                    setFormDataList(meter.fields)
                }
            })
    }, [selectedMeter])

    useEffect(() => {
        handleSetIpList(ipFullList);
        setStartIndexNoOfIpToShow(0)
        setEndIndexNoOfIpToShow(noOfIpToShow-1)
    }, [noOfIpToShow])

    useEffect(() => {
        console.log(startIndexNoOfIpToShow, endIndexNoOfIpToShow)
        handleSetIpList(ipFullList);
    }, [ startIndexNoOfIpToShow, endIndexNoOfIpToShow ])

    return (
        <>
            <div className='mt-[10px] border-2 border-lightgray rounded-xl p-3'>
                <div className="flex gap-2 items-center">
                    <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">{t('Generate_Reports')}</h3>
                </div>
                <div className="mt-[30px]">
                    <Listbox value={ selected } onChange={ (e) => setSelected(e) }>
                        {({ open }) => (
                            <>
                                <div className="flex items-center gap-[20px] flex-wrap">
                                    <Listbox.Label className="text-xl leading-6 font-bold text-[#5a7bad]">
                                        Select IP :
                                    </Listbox.Label>
                                    <div className="relative">
                                        <Listbox.Button className="relative w-[240px] cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6">
                                            <span className="block truncate text-xl sm:leading-6 text-[#5a7bad]">
                                                {selected}
                                            </span>
                                            <span className="pointer-events-none absolute top-[50%] translate-y-[-50%] right-0 flex items-center pr-2 mt-[-4px]">
                                                <FaSortDown className="text-[18px] text-gray-400" aria-hidden="true" />
                                            </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={ open }
                                            as={ Fragment }
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0">
                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-[16px]">
                                                {ipFullList && ipFullList.map((data) => (
                                                    <Listbox.Option
                                                        key={ data.ip_address }
                                                        className={ ({ active }) =>
                                                            classNames(
                                                                active ? 'bg-[#599bfb] text-white' : 'text-gray-900',
                                                                'relative cursor-default select-none py-2 pl-3 pr-9 '
                                                            )
                                                        }
                                                        value={ data.ip_address }>
                                                        {({ selected }) => (
                                                            <>
                                                                <span
                                                                    className={ classNames(
                                                                        selected ? 'font-semibold' : 'font-normal',
                                                                        'block truncate'
                                                                    ) }>
                                                                    {data.ip_address}
                                                                </span>
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div>

                <div className="mt-[30px]">
                    <Listbox value={ selectedSite } onChange={ (e) => setSelectedSite(e) }>
                        {({ open }) => (
                            <>
                                <div className="flex items-center gap-[20px] flex-wrap">
                                    <Listbox.Label className="text-xl leading-6 font-bold text-[#5a7bad]">
                                        Select site :
                                    </Listbox.Label>
                                    <div className="relative">
                                        <Listbox.Button className="relative w-[240px] cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6">
                                            <span className="block truncate text-xl sm:leading-6 text-[#5a7bad]">
                                                {selectedSite}
                                            </span>
                                            <span className="pointer-events-none absolute top-[50%] translate-y-[-50%] right-0 flex items-center pr-2 mt-[-4px]">
                                                <FaSortDown className="text-[18px] text-gray-400" aria-hidden="true" />
                                            </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={ open }
                                            as={ Fragment }
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0">
                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-[16px]">
                                                {sitesConfig && sitesConfig.map((data) => (
                                                    <Listbox.Option
                                                        key={ data.site }
                                                        className={ ({ active }) =>
                                                            classNames(
                                                                active ? 'bg-[#599bfb] text-white' : 'text-gray-900',
                                                                'relative cursor-default select-none py-2 pl-3 pr-9 '
                                                            )
                                                        }
                                                        value={ data.site }>
                                                        {({ selected }) => (
                                                            <>
                                                                <span
                                                                    className={ classNames(
                                                                        selected ? 'font-semibold' : 'font-normal',
                                                                        'block truncate'
                                                                    ) }>
                                                                    {data.site}
                                                                </span>
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div>

                {formSiteConfig.map((formData, index) => (
                    <div key={ index } className='bg-[#f6fbff] mt-[10px] mb-[10px] w-full p-4 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl space-y-3'>
                        <div className='grid md:grid-cols-8 sm:grid-cols-2 gap-[20px]'>
                            <div className='w-full'>
                                <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">
                                    IP
                                </label>
                                <div className="mt-1">
                                    <span
                                        className="appearance-none block w-full px-3 py-2  rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    >{ formData.ip }</span>
                                </div>
                            </div>
                            <div className='w-full'>
                                <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                    Floor
                                </label>
                                <div className="mt-1">
                                    <span
                                        className="appearance-none block w-full px-3 py-2  rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    >{ formData.floor }</span>
                                </div>
                            </div>

                            <div className='w-full'>
                                <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                    Device Name
                                </label>
                                <div className="mt-1">
                                    <span
                                        className="appearance-none block w-full px-3 py-2  rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    >{ formData.deviceName }</span>
                                </div>
                            </div>

                            <div className='w-full'>
                                <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                    Dashboard Name
                                </label>
                                <div className="mt-1">
                                    <span
                                        className="appearance-none block w-full px-3 py-2  rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    >{ formData.dashboardName }</span>
                                </div>
                            </div>

                            {/* change to select */}
                            <div className='w-full'>
                                <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                    Company
                                </label>
                                <div className="mt-1">
                                    <span
                                        className="appearance-none block w-full px-3 py-2  rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    >{ formData.company }</span>
                                </div>
                            </div>

                            <div className='w-full'>
                                <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                    Slave Id
                                </label>
                                <div className="mt-1">
                                    <span
                                        className="appearance-none block w-full px-3 py-2  rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    >{ formData.slaveId }</span>
                                </div>
                            </div>
                        </div>

                    </div>
                ))}

            </div>
        </>
    );
};

export default GenerateReport;
