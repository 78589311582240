import React, { useState, Fragment, useEffect } from 'react';
import { Listbox, Switch, Transition, Dialog } from '@headlessui/react';
import { FaSortDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { API } from '../../api'
import { toast } from 'react-toastify'
import { localStorageGet } from '../../utils/localstorage'
import { CopyIcon } from '../../Assets/AllSvg'
import JSONPretty from 'react-json-pretty';
import JsonFormatter from 'react-json-formatter'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { io } from 'socket.io-client'
const socket = io('wss://system.innovura.app:3000/')
socket.connect()

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const IpMeter = () => {

    const jsonStyle = {
        propertyStyle: { color: 'black' },
        stringStyle: { color: 'green' },
        numberStyle: { color: 'red' }
    }

    const [ open1, setOpen1 ] = useState(false)
    const [ selectValue, setSelectValue ] = useState({ name: 'Select Dashboard' })
    const [ dbData, setDbData ] = useState([])
    const [ show, setShow ] = useState(false)
    const [ copyChart, setCopyChart ] = useState('')
    const userType = localStorageGet('type')

    const [ editedRow, setEditedRow ] = useState(null);
    const [ editedQotRow, setEditedQotRow ] = useState(null);
    const [ selectedQotId, setSelectedQotId ] = useState(null);
    const [ selected, setSelected ] = useState('Select IP');
    const [ selectedSite, setSelectedSite ] = useState('Select Site');
    const [ meterTypes, setMeterTypes ] = useState([])
    const [ selectedMeter, setSelectedMeter ] = useState('');
    const [ ipAddress, setIpAddress ] = useState('192.168.0.');
    const [ qotName, setQotName ] = useState('');
    const [ inputValue, setInputValue ] = useState('');
    const [ siteName, setSiteName ] = useState('');
    const [ category, setCategory ] = useState('');
    const [ ipList, setIpList ] = useState([]);
    const [ ipFullList, setIpFullList ] = useState([]);
    const [ qotList, setQotList ] = useState([]);
    const [ mqttData, setMqttData ] = useState([]);
    const [ usbData, setUseData ] = useState([]);
    const [ mqttIp, setMqttIp ] = useState('');
    const [ sitesConfig, setSitesConfig ] = useState([]);
    const [ noOfIpToShow, setNoOfIpToShow ] = useState(5);
    const [ startIndexNoOfIpToShow, setStartIndexNoOfIpToShow ] = useState(0);
    const [ endIndexNoOfIpToShow, setEndIndexNoOfIpToShow ] = useState(4);
    const arrayOfSelect = [ 5, 10, 20, 30, 40, 50, 100 ]

    const { t } = useTranslation()

    const [ formDataList, setFormDataList ] = useState([]);
    const [ formSlaveDevice, setFormSlaveDevice ] = useState([]);
    const [ formSiteConfig, setFormSiteConfig ] = useState([]);

    socket.on('connect', () => {
        console.log('connected')
    })

    function handleMqttData(data, topic) {
        if (topic == 'loggerdata/Nano-0-'+(''+mqttIp).split('.')[ 3 ] ) {
            const pdata = [...mqttData]
            let notfound = true
            const dat = JSON.parse(data)
            for(const [ index, d ] of pdata.entries()){
                console.log(d.slavid_id, dat.slavid_id)
                if (d.slavid_id == dat.slavid_id){
                    notfound = false
                    pdata[ index ] = dat
                }
            }
            if (notfound){
                pdata.push(dat)
            }
            console.log(pdata)
            setMqttData(pdata)
        }
        if (topic == 'usbdata/Nano-0-'+(''+mqttIp).split('.')[ 3 ] ) {
            setUseData((''+data).replace(/^"|"$/g, '').replace(/\\/g, ''))
        }
    }

    useEffect(() => {
        console.log(mqttData.length)
    }, [mqttData])

    socket.on('message', (e) => {
        if (e?.topic?.includes('loggerdata/Nano-0-'+(''+mqttIp).split('.')[ 3 ]) ) {
            handleMqttData(e.message, (''+e.topic))
        }
        if (e?.topic?.includes('usbdata/Nano-0-'+(''+mqttIp).split('.')[ 3 ]) ) {
            handleMqttData(e.message, (''+e.topic))
        }
    })

    function compareIPAddresses(a, b) {
        const numA = Number(
            a.ip_address.split('.')
                .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
                .reduce((a, v) => ((a += v), a), 0)
        );
        const numB = Number(
            b.ip_address.split('.')
                .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
                .reduce((a, v) => ((a += v), a), 0)
        );
        return numA - numB;
    }

    const handleSetIpList = (list) => {
        setIpList(list.slice(startIndexNoOfIpToShow, endIndexNoOfIpToShow+1))
    }

    const handleValueChange = (value, field, data) => {
        const index = ipList.findIndex(item => item._id === data._id);
        const updatedIpList = [...ipList];
        updatedIpList[ index ][ field ] = value;
        setIpFullList(updatedIpList);
        handleSetIpList(updatedIpList);
    }

    const handleQotValueChange = (value, field, data) => {
        const index = qotList.findIndex(item => item._id === data._id);
        const updatedIQotList = [...qotList];
        updatedIQotList[ index ][ field ] = value;
        setQotList(updatedIQotList);
    }

    const fetchIPData = async () => {
        try {
            const response = await API('/api/ip/list');
            if(response){
                const list = response.notification.sort(compareIPAddresses)
                setIpFullList(list)
                handleSetIpList(list);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleIncDecNoOfIpToShow = (value) => {
        if (value === 'inc') {
            console.log((endIndexNoOfIpToShow + noOfIpToShow), ipFullList.length-1)
            console.log((ipFullList.length - endIndexNoOfIpToShow), noOfIpToShow)
            if((endIndexNoOfIpToShow + noOfIpToShow) <= (ipFullList.length-1)){
                setStartIndexNoOfIpToShow(startIndexNoOfIpToShow + noOfIpToShow)
                setEndIndexNoOfIpToShow(endIndexNoOfIpToShow + noOfIpToShow)
            }
            else if(ipFullList.length - endIndexNoOfIpToShow < noOfIpToShow){
                setStartIndexNoOfIpToShow(endIndexNoOfIpToShow)
                setEndIndexNoOfIpToShow(ipFullList.length - 1)
            }
        } else if (value === 'dec') {
            if (endIndexNoOfIpToShow === ipFullList.length - 1){
                setStartIndexNoOfIpToShow(startIndexNoOfIpToShow - noOfIpToShow)
                setEndIndexNoOfIpToShow(startIndexNoOfIpToShow)
            }
            else if((startIndexNoOfIpToShow - noOfIpToShow) >= 0){
                setStartIndexNoOfIpToShow(startIndexNoOfIpToShow - noOfIpToShow)
                setEndIndexNoOfIpToShow(endIndexNoOfIpToShow - noOfIpToShow)
            }
        } else if (value === 'initial') {
            setStartIndexNoOfIpToShow(0)
            if ((ipFullList.length-1) < noOfIpToShow){
                setEndIndexNoOfIpToShow((ipFullList.length-1))
            } else{
                setEndIndexNoOfIpToShow(noOfIpToShow-1)
            }
        } else if (value === 'end') {
            setStartIndexNoOfIpToShow(ipFullList.length - noOfIpToShow)
            setEndIndexNoOfIpToShow(ipFullList.length - 1)
        }
    }

    const fetchQotData = async () => {
        try {
            const response = await API('/api/qot/list');
            if(response){
                const l = [...response.notification].sort((a, b) => {
                    if (a.qotName < b.qotName) {
                        return 1;
                    }
                    if (a.qotName > b.qotName) {
                        return -1;
                    }
                    return 0;
                })
                console.log(l)
                setQotList(l);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleAddIP = async () => {
        const data = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: {
                ip_address: ipAddress,
                available: false,
                to_ip: 'Nano-0-159',
                assigned: false,
                usbinstalled: false
            }
        }
        const response = await API('/api/ip/add', data)
        if(response.success){
            toast.success(response.message, { autoClose: 5000 })
            fetchIPData();
        }
    };

    const handleAddQotName = async () => {
        const data = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: {
                qotName: qotName
            }
        }
        console.log(data)
        const response = await API('/api/qot/add', data)
        if(response.success){
            toast.success(response.message, { autoClose: 5000 })
            fetchQotData();
        }
    };

    const DeleteSingleIPs = async(ipdetails) =>{
        const data = {
            method: 'DELETE',
        }
        const response = await API(`/api/ip/delete/${ ipdetails._id }`, data)
        if (response.success) {
            toast.success(response.message, { autoClose: 5000 })
            fetchIPData();
        }
    }

    function confirmDelete (data) {
        confirmAlert({
            title: 'Confirm to Delete?',
            message: '',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteSingleIPs(data)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const DeleteSingleQots = async(qotdetails) =>{
        const data = {
            method: 'DELETE',
        }
        const response = await API(`/api/qot/delete/${ qotdetails._id }`, data)
        if (response.success) {
            toast.success(response.message, { autoClose: 5000 })
            fetchQotData();
        }
    }

    function confirmDeleteQOT (data) {
        confirmAlert({
            title: 'Confirm to Delete?',
            message: '',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteSingleQots(data)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const fetchSlaveDevice = async() => {
        setFormSlaveDevice([]);
        try {
            const APIDATA = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { ip_address: selected }
            };
            const response = await API('/api/deviceslaves/list', APIDATA); // Updated the endpoint URL
            if (response.success) {
                toast.success(response.message, { autoClose: 5000 });
                setFormSlaveDevice(response.notification.slaves)
            }
            else{
                toast.success(response.message, { autoClose: 5000 });
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    const handleUpdateIP = async (data) => {
        setEditedRow(null);
        try {
            const APIDATA = {
                method: 'PUT', // Changed from 'POST' to 'PATCH'
                headers: { 'Content-Type': 'application/json' },
                data: {
                    ip_address: data.ip_address,
                    available: data.available, // Changed from data.handleValueChange to data.available
                    to_ip: data.to_ip,
                    assigned: data.assigned,
                    usbinstalled: data.usbinstalled
                }
            };
            const response = await API(`/api/ip/update/${ data._id }`, APIDATA); // Updated the endpoint URL
            if (response) {
                toast.success(response.message, { autoClose: 5000 });
                fetchIPData();
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    };

    const handleUpdateQot = async (data) => {
        setEditedQotRow(null);
        try {
            const APIDATA = {
                method: 'PUT', // Changed from 'POST' to 'PATCH'
                headers: { 'Content-Type': 'application/json' },
                data: {
                    qotName: data.qotName
                }
            };
            const response = await API(`/api/qot/update/${ data._id }`, APIDATA); // Updated the endpoint URL
            if (response) {
                toast.success(response.message, { autoClose: 5000 });
                fetchQotData();
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    };

    const handleCopyChart = async (db, qotId) => {
        console.log(db)
        setSelectValue(db);
        setSelectedQotId(qotId);
        setShow(false)
    }

    const setDashboardata = async () => {
        setSelectValue({ name: 'Select Dashboard' })

        const dropdownData = []
        const sites = await API('/api/site/lists')
        const dashboard = await API('/api/lists/dashboard')

        if(userType === 'super-admin'){
            sites.sites.map((val) => val.floors.map((flr) => {
                const dboardData = dashboard.dashboard.filter((db) => db.floorId === flr._id && db.siteId === val._id)
                dropdownData.push({ site: `${ val.name } : ${ flr.name }`, id: flr._id, dashboard: dboardData })
            }))
            setDbData(dropdownData)
        }
    }

    const handleUpdateQotAssignedDashaboard = async () => {
        setEditedQotRow(null);
        try {
            const APIDATA = {
                method: 'PUT', // Changed from 'POST' to 'PATCH'
                headers: { 'Content-Type': 'application/json' },
                data: {
                    dashboard_assigned_id: selectValue._id,
                    dashboard_site: selectValue.company,
                    dashboard_name: selectValue.name,
                    iot_host: selectValue.cards[ 0 ].groups[ 0 ].devices[ 0 ].iot_host,
                }
            };
            const response = await API(`/api/qot/update/${ selectedQotId }`, APIDATA); // Updated the endpoint URL
            if (response) {
                toast.success(response.message, { autoClose: 5000 });
                fetchQotData();
                setOpen1(false)

            }
            else{
                setOpen1(false)
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
            setOpen1(false)
        }
    };

    const handleAddField = () => {
        setFormDataList([ ...formDataList, { start_register_address: 0, name: '', unit: '', type_of_value: 'INT32', no_of_decimals: 2, no_of_registers: 2, signed: 'true', function_code: 3, byte_order: 0 }]);
    };

    const handleRemoveField = (index) => {
        const updatedDataList = [...formDataList];
        updatedDataList.splice(index, 1);
        setFormDataList(updatedDataList);
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedDataList = [...formDataList];
        updatedDataList[ index ][ name ] = value;
        setFormDataList(updatedDataList);
    };

    const handleAddFieldSlaveDevices = () => {
        setFormSlaveDevice([ ...formSlaveDevice, { slave_address: '', type: 'CVM-C10', baud_rate: 19200, parity: 'E', stopbits: 1, bytesize: 8, timeout: 0.5 }]);
    };

    const handleRemoveFieldSlaveDevices = (index) => {
        const updatedDataList = [...formSlaveDevice];
        updatedDataList.splice(index, 1);
        setFormSlaveDevice(updatedDataList);
    };

    const handleInputChangeSlaveDevices = (index, event) => {
        let { name, value } = event.target;
        console.log(name, value)
        if (name == 'timeout'){
            value = parseFloat(value)
        }
        const updatedDataList = [...formSlaveDevice];
        console.log(updatedDataList)
        updatedDataList[ index ][ name ] = value;
        setFormSlaveDevice(updatedDataList);
    };

    const AddDeviceSlave = async() => {
        try {
            const APIDATA = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { ip_address: selected, slaves: formSlaveDevice }
            };
            const response = await API('/api/deviceslaves/add', APIDATA); // Updated the endpoint URL
            if (response) {
                toast.success(response.message, { autoClose: 5000 });
                // fetchSlavesData();
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    const fetchMeterTypes = async() =>{
        try{
            const response = await API('/api/metertypes/list')
            if(response.success){
                toast.success(response.message, { autoClose: 5000 })
                setMeterTypes(response.notification)
            }
            else{
                toast.success(response.message, { autoClose: 5000 })
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    const handleMeterDataSave = async() => {
        try {
            const APIDATA = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { meterName: selectedMeter, fields: formDataList }
            };
            const response = await API('/api/metertypes/add', APIDATA); // Updated the endpoint URL
            if (response) {
                toast.success(response.message, { autoClose: 5000 });
                fetchMeterTypes();
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    const addMeterType = async() =>{
        try {
            const data = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    meterName: inputValue,
                    fields: []
                }
            }
            const response = await API('/api/metertypes/add', data)
            if(response.success){
                toast.success(response.message, { autoClose: 5000 })
                fetchMeterTypes()
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    const fetchSiteConfig = async () => {
        try {
            const response = await API('/api/sitesconfig/list');
            if(response){
                setSitesConfig(response.notification);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const addSiteConfig = async(isUpdate = false) =>{
        try {
            const data = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    site: isUpdate ? (sitesConfig.find((e) => e.site === selectedSite)).site : siteName,
                    category: isUpdate ? (sitesConfig.find((e) => e.site === selectedSite)).category : category,
                    config: formSiteConfig
                }
            }
            const response = await API('/api/sitesconfig/add', data)
            if(response.success){
                toast.success(response.message, { autoClose: 5000 })
                fetchSiteConfig()
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    const generateOrDeleteSite = async(generate, deleteInfo = false) =>{
        try {
            const data = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    IdOfSiteConfig: (sitesConfig.find((e) => e.site === selectedSite))._id,
                    deleteInfo
                }
            }
            const response = await API('/api/sitesconfig/'+(generate == true ? 'createSites': 'deleteSites'), data)
            if(response.success){
                toast.success(response.message, { autoClose: 5000 })
            }
            else if(response.success == false){
                toast.error(response.message, { autoClose: 5000 })
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    const deleteAndCreateSite = async(generate, site, deleteInfo = false) =>{
        const data = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: {
                IdOfSiteConfig: site._id,
                deleteInfo
            }
        }
        const response = await API('/api/sitesconfig/'+(generate == true ? 'createSites': 'deleteSites'), data)
        if(response.success){
            toast.success(response.message, { autoClose: 5000 })
            return true
        }
        else if(response.success == false){
            toast.error(response.message, { autoClose: 5000 })
            return false
        }
    }

    const regenerateAllSites = async() => {
        try {
            for(const site of sitesConfig){
                await deleteAndCreateSite(false, site, false)
                await deleteAndCreateSite(true, site, false)
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    const fetchSingleSiteConfig = async() =>{
        setFormSiteConfig([]);
        try {
            const APIDATA = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { site: selectedSite }
            };
            const response = await API('/api/sitesconfig/list', APIDATA); // Updated the endpoint URL
            if (response.success) {
                toast.success(response.message, { autoClose: 5000 });
                setFormSiteConfig(response.notification.config)
            }
            else{
                toast.success(response.message, { autoClose: 5000 });
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    const handleInputChangeSitesConfig = (index, event) => {
        let { name, value } = event.target;
        if (name == 'timeout'){
            value = parseFloat(value)
        }
        const updatedDataList = [...formSiteConfig];
        updatedDataList[ index ][ name ] = value;
        setFormSiteConfig(updatedDataList);
    };

    const handleRemoveFieldSiteConfig = (index) => {
        const updatedDataList = [...formSiteConfig];
        updatedDataList.splice(index, 1);
        setFormSiteConfig(updatedDataList);
    };

    const handleAddFieldSiteConfig = () => {
        setFormSiteConfig([ ...formSiteConfig, { ip:'', floor:'', deviceName:'', dashboardName:'', company:'', slaveId:1, kVAHistCalcVoltAndAmp:1000, kVAHistCalc:1000, voltCalc:10, pfCalc:100, currentCalc:1000 }]);
    };

    function confirmDeleteHandleRemoveFieldSlaveDevices (index) {
        confirmAlert({
            title: 'Confirm to Delete?',
            message: '',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleRemoveFieldSlaveDevices(index)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    function confirmDeleteHandleRemoveFieldSiteConfig (index) {
        confirmAlert({
            title: 'Confirm to Delete?',
            message: '',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleRemoveFieldSiteConfig(index)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    function confirmDeleteHandleRemoveField (index) {
        confirmAlert({
            title: 'Confirm to Delete?',
            message: '',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleRemoveField(index)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    useEffect(() => {
        fetchIPData();
        fetchQotData();
        fetchMeterTypes();
        fetchSiteConfig();
        setDashboardata();
    }, []);

    useEffect(() => {
        if (selected !== 'Select IP')
            fetchSlaveDevice()
    }, [selected])

    useEffect(() => {
        if (selected !== 'Select Site')
            fetchSingleSiteConfig()
    }, [selectedSite])

    useEffect(() => {
        if (selectedMeter !== '')
            meterTypes.map((meter, index) => {
                if (meter.meterName === selectedMeter){
                    setFormDataList(meter.fields)
                }
            })
    }, [selectedMeter])

    useEffect(() => {
        handleSetIpList(ipFullList);
        setStartIndexNoOfIpToShow(0)
        setEndIndexNoOfIpToShow(noOfIpToShow-1)
    }, [noOfIpToShow])

    useEffect(() => {
        console.log(startIndexNoOfIpToShow, endIndexNoOfIpToShow)
        handleSetIpList(ipFullList);
    }, [ startIndexNoOfIpToShow, endIndexNoOfIpToShow ])

    return (
        <>
            <div className='border-2 border-lightgray rounded-xl p-3'>
                <div className="flex gap-2 items-center">
                    <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">{t('IP_and_Meter_Settings')}</h3>
                </div>
                <div className="mt-[30px]">
                    <div className="flex items-center flex-wrap gap-[24px]">
                        <p className="text-xl leading-6 font-bold text-[#5a7bad] ">
                            New IP :
                        </p>
                        <input placeholder='192.168.0.' value={ ipAddress }
                            onChange={ (e) => setIpAddress(e.target.value) } className=' rounded-md bg-white py-1.5 px-4 max-w-[240px] w-full text-left shadow-sm ring-1 ring-inset ring-gray-300 text-xl sm:leading-6 text-[#5a7bad]' />
                        <button onClick={ handleAddIP }
                            className="inline-flex items-center px-4 py-2 border border-transparent text-md font-semibold rounded-md shadow-sm text-white bg-[#599bfb]">
                            Add IP
                        </button>
                    </div>
                </div>
                <div className="mt-6 overflow-x-auto">
                    <table className="w-[938px] overflow-x-auto lg:w-full divide-y divide-gray-200 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-[15px]">
                        <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                    IP
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                    Assign To
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                    Available
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                    Assigned
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                    USB installed?
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                    See Data
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {ipList && ipList.map((data, key) => (
                                <tr key={ key } className={ editedRow && editedRow._id === data._id ? '' : 'device-salves' } >
                                    <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-blue-600">
                                        <input
                                            value={ data.ip_address }
                                            onChange={ (e) => handleValueChange(e.target.value, 'ip_address', data) }
                                            className='p-[4px_10px] w-[150px]'
                                        />
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-500">
                                        <div className="relative flex items-start">
                                            <div className="flex h-6 items-center">
                                                <input
                                                    value={ data.to_ip }
                                                    onChange={ (e) => handleValueChange(e.target.value, 'to_ip', data) }
                                                    type="text"
                                                    id="to_ip"
                                                    name="to_ip"
                                                    className="h-[100%] rounded border-gray-300 text-[#599bfb]"
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-500">
                                        <Switch
                                            checked={ data.available }
                                            onChange={ (e) => handleValueChange(!data.available, 'available', data) }
                                            className={ classNames(
                                                data.available ? 'bg-[#599bfb]' : 'bg-gray-200',
                                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out'
                                            ) }
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span
                                                aria-hidden="true"
                                                className={ classNames(
                                                    data.available  ? 'translate-x-5' : 'translate-x-0',
                                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                ) }
                                            />
                                        </Switch>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-500">
                                        <div className="relative flex items-start">
                                            <div className="flex h-6 items-center">
                                                <input
                                                    checked={ data.assigned }
                                                    onChange={ (e) => handleValueChange(e.target.checked, 'assigned', data) }
                                                    type="checkbox"
                                                    id="comments"
                                                    aria-describedby="comments-description"
                                                    name="comments"
                                                    className="h-[22px] w-[22px] rounded border-gray-300 text-[#599bfb]"
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-500">
                                        <div className="relative flex items-start">
                                            <div className="flex h-6 items-center">
                                                <input
                                                    checked={ data.usbinstalled }
                                                    onChange={ (e) => handleValueChange(e.target.checked, 'usbinstalled', data) }
                                                    type="checkbox"
                                                    id="comments"
                                                    aria-describedby="comments-description"
                                                    name="comments"
                                                    className="h-[22px] w-[22px] rounded border-gray-300 text-[#599bfb]"
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-500 pointer-events-auto">
                                        <div className="relative flex items-start">
                                            <div className="flex h-6 items-center">
                                                <button onClick={ () => {
                                                    if (mqttIp !== ''){
                                                        toast.error('Please remove '+ data.ip_address +' ip first.')
                                                    }
                                                    else{
                                                        setMqttIp(''); setMqttIp(data.ip_address); setMqttData([]); toast.info('Waiting for data from: '+data.ip_address)
                                                    }
                                                } } className="text-[#5a7bad] hover:text-primary-focus mr-3">See Data</button>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-md font-semibold">
                                        { editedRow !== null && data._id == editedRow._id  ? (
                                            <>
                                                <button onClick={ () => handleUpdateIP(data) } className="text-[#5a7bad] hover:text-primary-focus mr-3">{t('Save')}</button>
                                                {/* <button onClick={ ()=> handleCancel() }><a className="text-[#599bfb] cursor-pointer">{t('Cancel')}</a></button> */}
                                            </>

                                        ) : (
                                            <>
                                                <button onClick={ () => setEditedRow(data) } className="text-primary hover:text-primary-focus mr-3">{t('Edit')}</button>
                                                <button onClick={ () => confirmDelete(data) }>
                                                    <a className="text-red-500 cursor-pointer">{t('Delete')}</a>
                                                </button>
                                            </>
                                        )}
                                    </td>

                                </tr>
                            )
                            )}
                        </tbody>
                    </table>
                </div>
                <div>
                    <div className="mt-3 flex items-center flex-wrap gap-[24px]">
                        <div className="mt-1 flex">
                            <h6 className="leading-6 font-bold text-[#5a7bad] mt-1 mr-3">{t('QOTLOGGER')} Show No. of IP: </h6>
                            <select
                                className="appearance-none block px-3 py-2 border border-gray-300 rounded outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                value={ noOfIpToShow }
                                onChange={ (e) => setNoOfIpToShow(e.target.value) }>
                                {arrayOfSelect.length > 0 && arrayOfSelect.map((noOfSelects, index) => {
                                    return <option key={ index } value={ noOfSelects }>{ noOfSelects }</option>
                                })}
                            </select>
                            <button className='ml-10 py-[5px] w-[40px] font-semibold rounded-lg bg-[#599bfb] text-white px-[3px] flex justify-center text-[15px]'
                                onClick={ () => { handleIncDecNoOfIpToShow('initial') } }
                            >
                                |&lt;
                            </button>
                            <button className='ml-10 py-[5px] w-[40px] font-semibold rounded-lg bg-[#599bfb] text-white px-[3px] flex justify-center text-[15px]'
                                onClick={ () => { handleIncDecNoOfIpToShow('dec') } }
                            >
                                &lt;
                            </button>
                            <button className='ml-10 py-[5px] w-[40px] font-semibold rounded-lg bg-[#599bfb] text-white px-[3px] flex justify-center text-[15px]'
                                onClick={ () => { handleIncDecNoOfIpToShow('inc') } }
                            >
                                &gt;
                            </button>
                            <button className='ml-10 py-[5px] w-[40px] font-semibold rounded-lg bg-[#599bfb] text-white px-[3px] flex justify-center text-[15px]'
                                onClick={ () => { handleIncDecNoOfIpToShow('end') } }
                            >
                                &gt;|
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {
                mqttIp !== '' && <div className="mt-6 overflow-x-auto border-2 border-lightgray rounded-xl p-3">
                    <p className="text-xl leading-6 font-bold text-[#5a7bad] ">
                        Data from {mqttIp}  <button className='mt-[30px] py-[10px] w-[80px] font-semibold rounded-lg bg-[#599bfb] text-white px-[5px] flex justify-center mb-2 text-[15px]'
                            onClick={ () => { setMqttIp(''); window.location.reload() } }
                        >
                            Remove
                        </button>
                    </p>
                    <div className='flex'>
                        <div>
                            <h1>MQTT Data</h1>
                            <div className='flex'>
                                {mqttData && mqttData.length > 0 && mqttData.map((data, index) => (
                                    <JsonFormatter key={ index } json={ data } tabWith={ 4 } jsonStyle={ jsonStyle } />
                                ))}
                            </div>
                        </div>

                        <div className='ml-10'>
                            <h1>USB data</h1>
                            <JsonFormatter json={ usbData } tabWith={ 4 } jsonStyle={ jsonStyle } />
                        </div>
                    </div>

                </div>
            }

            <div className='mt-[60px] border-2 border-lightgray rounded-xl p-3'>
                <div className="flex gap-2 items-center">
                    <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">Device Slaves</h3>
                </div>
                <div className="mt-[30px]">
                    <Listbox value={ selected } onChange={ (e) => setSelected(e) }>
                        {({ open }) => (
                            <>
                                <div className="flex items-center gap-[20px] flex-wrap">
                                    <Listbox.Label className="text-xl leading-6 font-bold text-[#5a7bad]">
                                        Select IP :
                                    </Listbox.Label>
                                    <div className="relative">
                                        <Listbox.Button className="relative w-[240px] cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6">
                                            <span className="block truncate text-xl sm:leading-6 text-[#5a7bad]">
                                                {selected}
                                            </span>
                                            <span className="pointer-events-none absolute top-[50%] translate-y-[-50%] right-0 flex items-center pr-2 mt-[-4px]">
                                                <FaSortDown className="text-[18px] text-gray-400" aria-hidden="true" />
                                            </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={ open }
                                            as={ Fragment }
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0">
                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-[16px]">
                                                {ipFullList && ipFullList.map((data) => (
                                                    <Listbox.Option
                                                        key={ data.ip_address }
                                                        className={ ({ active }) =>
                                                            classNames(
                                                                active ? 'bg-[#599bfb] text-white' : 'text-gray-900',
                                                                'relative cursor-default select-none py-2 pl-3 pr-9 '
                                                            )
                                                        }
                                                        value={ data.ip_address }>
                                                        {({ selected }) => (
                                                            <>
                                                                <span
                                                                    className={ classNames(
                                                                        selected ? 'font-semibold' : 'font-normal',
                                                                        'block truncate'
                                                                    ) }>
                                                                    {data.ip_address}
                                                                </span>
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div>

                <div className='flex gap-3'>
                    <button
                        className='mt-[30px] py-[15px] w-[80px] font-semibold rounded-lg bg-[#599bfb] text-white px-[5px] flex justify-center mb-2 text-[20px]'
                        onClick={ ()=> handleAddFieldSlaveDevices() }
                    >
                        <IoMdAddCircleOutline />
                    </button>

                    <button
                        className='mt-[30px] py-[10px] w-[80px] font-semibold rounded-lg bg-[#599bfb] text-white px-[5px] flex justify-center mb-2 text-[18px]'
                        onClick={ () => AddDeviceSlave() }
                    >
                        Save
                    </button>
                </div>

                {formSlaveDevice.map((formData, index) => (
                    <div key={ index } className='bg-[#f6fbff] mt-[10px] mb-[10px] w-full p-4 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl space-y-3'>
                        <div className='grid md:grid-cols-8 sm:grid-cols-2 gap-[20px]'>
                            <div className='w-full'>
                                <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">
                                    Slave Address
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="slave_address"
                                        type="number"
                                        value={ formData.slave_address }
                                        onChange={ (e) => handleInputChangeSlaveDevices(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className='w-full'>
                                <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                    Type
                                </label>
                                <div className="mt-1">
                                    <select
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                        value={ formData.type }
                                        onChange={ (e) => handleInputChangeSlaveDevices(index, e) } name="type" id="type">
                                        {meterTypes.length > 0 && meterTypes.map((meter, index) => {
                                            return <option key={ index } value={ meter.meterName }>{ meter.meterName }</option>
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className='w-full'>
                                <label htmlFor="no_of_decimals" className="block text-sm font-medium text-[#5a7bad]">
                                    Baud Rate
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="baud_rate"
                                        type="number"
                                        defaultValue="11520"
                                        placeholder="11520"
                                        value={ formData.baud_rate }
                                        onChange={ (e) => handleInputChangeSlaveDevices(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className='w-full'>
                                <label htmlFor="function_code" className="block text-sm font-medium text-[#5a7bad]">
                                    Parity
                                </label>
                                <div className="mt-1">
                                    <select className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" value={ formData.parity }
                                        onChange={ (e) => handleInputChangeSlaveDevices(index, e) } name="parity" id="parity">
                                        <option value="E">E</option>
                                        <option value="N">N</option>
                                        <option value="O">O</option>
                                        <option value="M">M</option>
                                        <option value="S">S</option>
                                    </select>
                                </div>
                            </div>
                            <div className='w-full'>
                                <label htmlFor="function_code" className="block text-sm font-medium text-[#5a7bad]">
                                    Stop Bits
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="stopbits"
                                        type="number"
                                        value={ formData.stopbits }
                                        onChange={ (e) => handleInputChangeSlaveDevices(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className='w-full'>
                                <label htmlFor="function_code" className="block text-sm font-medium text-[#5a7bad]">
                                    Byte Size
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="bytesize"
                                        type="number"
                                        value={ formData.bytesize }
                                        onChange={ (e) => handleInputChangeSlaveDevices(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className='w-full'>
                                <label htmlFor="function_code" className="block text-sm font-medium text-[#5a7bad]">
                                    Timeout
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="timeout"
                                        step="0.1"
                                        type="number"
                                        value={ typeof formData.timeout == 'object' ? formData.timeout.$numberDecimal : parseFloat(formData.timeout) }
                                        onChange={ (e) => handleInputChangeSlaveDevices(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <button className='font-semibold mt-5' onClick={ () => confirmDeleteHandleRemoveFieldSlaveDevices(index) }>
                                <a className="text-red-500 cursor-pointer">{t('Delete')}</a>
                            </button>
                        </div>

                    </div>
                ))}
            </div>

            <div className='mt-[60px] border-2 border-lightgray rounded-xl p-3'>
                <div className="flex gap-2 items-center">
                    <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">Sites Configuration</h3>
                </div>
                <div className="flex items-center flex-wrap gap-[24px] mt-[20px]">
                    <p className="text-xl leading-6 font-bold text-[#5a7bad] w-[160px]">
                        Add Site :
                    </p>
                    <input
                        value={ siteName }
                        onChange={ (e)=> setSiteName(e.target.value) }
                        className='rounded-md bg-white py-1.5 px-4 max-w-[240px] w-full text-left shadow-sm ring-1 ring-inset ring-gray-300 text-xl sm:leading-6 text-[#5a7bad]'
                    />
                    <p className="text-xl leading-6 font-bold text-[#5a7bad] w-[160px]">
                        Site Category :
                    </p>
                    <input
                        value={ category }
                        onChange={ (e)=> setCategory(e.target.value) }
                        className='rounded-md bg-white py-1.5 px-4 max-w-[240px] w-full text-left shadow-sm ring-1 ring-inset ring-gray-300 text-xl sm:leading-6 text-[#5a7bad]'
                    />
                    <button
                        className="inline-flex items-center px-4 py-2 border border-transparent text-md font-semibold rounded-md shadow-sm text-white bg-[#599bfb]"
                        onClick={ ()=> addSiteConfig() }
                    >
                        Add Site
                    </button>
                </div>
                <div className="mt-[30px]">
                    <Listbox value={ selectedSite } onChange={ (e) => setSelectedSite(e) }>
                        {({ open }) => (
                            <>
                                <div className="flex items-center gap-[20px] flex-wrap">
                                    <Listbox.Label className="text-xl leading-6 font-bold text-[#5a7bad]">
                                        Select site :
                                    </Listbox.Label>
                                    <div className="relative">
                                        <Listbox.Button className="relative w-[240px] cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6">
                                            <span className="block truncate text-xl sm:leading-6 text-[#5a7bad]">
                                                {selectedSite}
                                            </span>
                                            <span className="pointer-events-none absolute top-[50%] translate-y-[-50%] right-0 flex items-center pr-2 mt-[-4px]">
                                                <FaSortDown className="text-[18px] text-gray-400" aria-hidden="true" />
                                            </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={ open }
                                            as={ Fragment }
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0">
                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-[16px]">
                                                {sitesConfig && sitesConfig.map((data) => (
                                                    <Listbox.Option
                                                        key={ data.site }
                                                        className={ ({ active }) =>
                                                            classNames(
                                                                active ? 'bg-[#599bfb] text-white' : 'text-gray-900',
                                                                'relative cursor-default select-none py-2 pl-3 pr-9 '
                                                            )
                                                        }
                                                        value={ data.site }>
                                                        {({ selected }) => (
                                                            <>
                                                                <span
                                                                    className={ classNames(
                                                                        selected ? 'font-semibold' : 'font-normal',
                                                                        'block truncate'
                                                                    ) }>
                                                                    {data.site}
                                                                </span>
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div>

                <div className='flex gap-3'>
                    <button
                        className='mt-[30px] py-[15px] w-[80px] font-semibold rounded-lg bg-[#599bfb] text-white px-[5px] flex justify-center mb-2 text-[20px]'
                        onClick={ ()=> handleAddFieldSiteConfig() }
                    >
                        <IoMdAddCircleOutline />
                    </button>

                    <button
                        className='mt-[30px] py-[10px] w-[80px] font-semibold rounded-lg bg-[#599bfb] text-white px-[5px] flex justify-center mb-2 text-[18px]'
                        onClick={ () => addSiteConfig(true) }
                    >
                        Save
                    </button>

                    <button
                        className='mt-[30px] py-[10px] w-[180px] font-semibold rounded-lg bg-green-500 text-white px-[5px] flex justify-center mb-2 text-[18px]'
                        onClick={ () => regenerateAllSites() }
                    >
                        Regenerate All Sites
                    </button>

                    <button
                        className='mt-[30px] py-[10px] w-[150px] font-semibold rounded-lg bg-green-500 text-white px-[5px] flex justify-center mb-2 text-[18px]'
                        onClick={ () => generateOrDeleteSite(true) }
                    >
                        Generate Site
                    </button>

                    <button
                        className='mt-[30px] py-[10px] w-150px] font-semibold rounded-lg bg-red-500 text-white px-[5px] flex justify-center mb-2 text-[18px]'
                        onClick={ () => generateOrDeleteSite(false) }
                    >
                        Delete Site only
                    </button>
                    <button
                        className='mt-[30px] py-[10px] w-150px] font-semibold rounded-lg bg-red-500 text-white px-[5px] flex justify-center mb-2 text-[18px]'
                        onClick={ () => generateOrDeleteSite(false, true) }
                    >
                        Delete site and site info
                    </button>
                </div>

                {formSiteConfig.map((formData, index) => (
                    <div key={ index } className='bg-[#f6fbff] mt-[10px] mb-[10px] w-full p-4 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl space-y-3'>
                        <div className='grid md:grid-cols-8 sm:grid-cols-2 gap-[20px]'>
                            <div className='w-full'>
                                <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">
                                    IP
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="ip"
                                        type="text"
                                        value={ formData.ip }
                                        onChange={ (e) => handleInputChangeSitesConfig(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className='w-full'>
                                <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                    Floor
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="floor"
                                        type="text"
                                        value={ formData.floor }
                                        onChange={ (e) => handleInputChangeSitesConfig(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className='w-full'>
                                <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                    Device Name
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="deviceName"
                                        type="text"
                                        value={ formData.deviceName }
                                        onChange={ (e) => handleInputChangeSitesConfig(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className='w-full'>
                                <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                    Dashboard Name
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="dashboardName"
                                        type="text"
                                        value={ formData.dashboardName }
                                        onChange={ (e) => handleInputChangeSitesConfig(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>

                            {/* change to select */}
                            <div className='w-full'>
                                <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                    Company
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="company"
                                        type="text"
                                        value={ formData.company }
                                        onChange={ (e) => handleInputChangeSitesConfig(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className='w-full'>
                                <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                    Slave Id
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="slaveId"
                                        type="number"
                                        value={ formData.slaveId }
                                        onChange={ (e) => handleInputChangeSitesConfig(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className='w-full'>
                                <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                    kVA V&A Factor
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="kVAHistCalcVoltAndAmp"
                                        type="number"
                                        value={ formData.kVAHistCalcVoltAndAmp }
                                        onChange={ (e) => handleInputChangeSitesConfig(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className='w-full'>
                                <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                    kVA Calc. Factor
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="kVAHistCalc"
                                        type="number"
                                        value={ formData.kVAHistCalc }
                                        onChange={ (e) => handleInputChangeSitesConfig(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className='w-full'>
                                <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                    Current Calc. Factor
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="currentCalc"
                                        type="number"
                                        value={ formData.currentCalc }
                                        onChange={ (e) => handleInputChangeSitesConfig(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className='w-full'>
                                <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                    Voltage Calc. Factor
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="voltCalc"
                                        type="number"
                                        value={ formData.voltCalc }
                                        onChange={ (e) => handleInputChangeSitesConfig(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className='w-full'>
                                <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                    PF Calc. Factor
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="pfCalc"
                                        type="number"
                                        value={ formData.pfCalc }
                                        onChange={ (e) => handleInputChangeSitesConfig(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <button className='font-semibold mt-5' onClick={ () => confirmDeleteHandleRemoveFieldSiteConfig(index) }>
                                <a className="text-red-500 cursor-pointer">{t('Delete')}</a>
                            </button>
                        </div>

                    </div>
                ))}
            </div>

            <div className='mt-[60px] border-2 border-lightgray rounded-xl p-3'>
                <div className="flex gap-2 items-center">
                    <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">QOT Loggers</h3>
                </div>
                <div className="mt-[30px]">
                    <div className="flex items-center flex-wrap gap-[24px]">
                        <p className="text-xl leading-6 font-bold text-[#5a7bad] ">
                            Add QOT Logger (must be same as Qot logger) :
                        </p>
                        <input placeholder='' value={ qotName }
                            onChange={ (e) => setQotName(e.target.value) } className=' rounded-md bg-white py-1.5 px-4 max-w-[240px] w-full text-left shadow-sm ring-1 ring-inset ring-gray-300 text-xl sm:leading-6 text-[#5a7bad]' />
                        <button onClick={ () => { handleAddQotName() } }
                            className="inline-flex items-center px-4 py-2 border border-transparent text-md font-semibold rounded-md shadow-sm text-white bg-[#599bfb]">
                            Add
                        </button>
                    </div>
                </div>
                <div className="mt-6 overflow-x-auto">
                    <table className="w-[938px] overflow-x-auto lg:w-full divide-y divide-gray-200 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-[15px]">
                        <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                    QOT Name
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                    Assign to dashboard
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {qotList && qotList.map((data, key) => (
                                <tr key={ key } className={ editedQotRow && editedQotRow._id === data._id ? '' : 'device-salves' } >
                                    <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-blue-600">
                                        <input defaultValue={ data.qotName } onChange={ (e) => handleQotValueChange(e.target.value, 'qotName', data) } className='p-[4px_10px] w-[300px]' />
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-md font-semibold text-gray-500">
                                        <div className="relative flex items-start">
                                            <div className="flex h-6 items-center">
                                                {data.dashboard_site} {data.dashboard_site && ' - '} {data.dashboard_name} {data.dashboard_site && ' - '} ({data.iot_host})

                                                <Transition.Root show={ open1 } as={ Fragment }>
                                                    <Dialog as="div" className="relative z-10" onClose={ () => setOpen1(false) }>
                                                        <Transition.Child
                                                            as={ Fragment }
                                                            enter="ease-out duration-300"
                                                            enterFrom="opacity-0"
                                                            enterTo="opacity-100"
                                                            leave="ease-in duration-200"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <div className="fixed inset-0 bg-[#292929] bg-opacity-75 transition-opacity" />
                                                        </Transition.Child>

                                                        <div className="fixed inset-0 z-10 overflow-y-auto">
                                                            <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                                                                <Transition.Child
                                                                    as={ Fragment }
                                                                    enter="ease-out duration-300"
                                                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                                    leave="ease-in duration-200"
                                                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                                >
                                                                    <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                                                                        <div>
                                                                            <div className='flex gap-[15px]'>
                                                                                <div className='bg-[#599bfb] h-fit p-3 rounded-lg'>
                                                                                    <CopyIcon />
                                                                                </div>
                                                                                <div>
                                                                                    <h5 className='text-[#599bfb] font-medium text-xl'>{t('Select')} </h5>
                                                                                    <h5 className='text-[#5a7bad] font-normal'>{t('qotSelect')}</h5>
                                                                                    <div className='w-full relative mt-2'>
                                                                                        <label htmlFor="User Type" className="block text-sm font-medium text-[#5a7bad]">
                                                                                            {t('Dashboard')}
                                                                                        </label>
                                                                                        <div className='relative group mt-1'>
                                                                                            <input value={ selectValue.name } onClick={ ()=>setShow(!show) } type="text" className="block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] text-[#5a7bad] sm:text-sm" />
                                                                                            {show &&
                                                                                            <div className='absolute top-[110%] bg-white py-[5px] border-gray-300 border-[1px] rounded-lg w-full'>
                                                                                                {dbData && dbData.map((val, i) =>
                                                                                                    <div key={ i }>
                                                                                                        <div className='py-[5px] font-bold text-sm px-[20px] text-[#599bfb] font-medium cursor-pointer hover:bg-slate-100'># {val.site}</div>
                                                                                                        {val.dashboard?.map((db) =>
                                                                                                            <div key={ db._id } onClick={ ()=> handleCopyChart(db, data._id) } className='py-[5px] px-[20px] pl-[35px] text-sm text-[#5a7bad] font-medium cursor-pointer hover:bg-slate-100'>{db.name}</div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='flex justify-end gap-3 mt-6'>
                                                                                <button onClick={ ()=> setOpen1(false) } className='px-[15px] py-[5px] font-medium text-[#5a7bad] rounded-lg border-[1px] border-[#5a7bad]'>{t('Cancel')}</button>
                                                                                {userType === 'super-admin' && <button onClick={ () => { handleUpdateQotAssignedDashaboard() } } className='px-[15px] py-[5px] bg-[#5a7bad] font-medium text-[#fff] rounded-lg border-[1px] border-[#5a7bad]'>{t('Select')}</button>}
                                                                            </div>
                                                                        </div>
                                                                    </Dialog.Panel>
                                                                </Transition.Child>
                                                            </div>
                                                        </div>
                                                    </Dialog>
                                                </Transition.Root>

                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-md font-semibold">
                                        { editedQotRow !== null && data._id == editedQotRow._id  ? (
                                            <>
                                                <button onClick={ () => setOpen1(true) } className="text-[#5a7bad] hover:text-primary-focus mr-3">Select Dashboard</button>
                                                <button onClick={ () => handleUpdateQot(data) } className="text-[#5a7bad] hover:text-primary-focus mr-3">{t('Save')}</button>
                                                {/* <button onClick={ ()=> handleCancel() }><a className="text-[#599bfb] cursor-pointer">{t('Cancel')}</a></button> */}
                                            </>

                                        ) : (
                                            <>
                                                <button onClick={ () => setEditedQotRow(data) } className="text-primary hover:text-primary-focus mr-3">{t('Edit')}</button>
                                                <button onClick={ () => confirmDeleteQOT(data) } >
                                                    <a className="text-red-500 cursor-pointer">{t('Delete')}</a>
                                                </button>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            )
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className='mt-[60px] mb-10 border-2 border-lightgray rounded-xl p-3'>
                <div className="flex gap-2 items-center">
                    <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">Meter Type</h3>
                </div>

                <div className="mt-[30px]">
                    <Listbox value={ selectedMeter } onChange={ (e) => { setSelectedMeter(e) } }>
                        {({ open }) => (
                            <>
                                <div className="flex items-center gap-[24px] flex-wrap">
                                    <Listbox.Label className="text-xl w-[160px] leading-6 font-bold text-[#5a7bad]">
                                        Select Type :
                                    </Listbox.Label>
                                    <div className="relative">
                                        <Listbox.Button className="relative w-[240px] py-4 cursor-default rounded-md bg-white pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6">
                                            <span className="block truncate text-xl sm:leading-6 text-[#5a7bad]">
                                                { selectedMeter }
                                            </span>
                                            <span className="pointer-events-none absolute top-[50%] translate-y-[-50%] right-0 flex items-center pr-2 mt-[-4px]">
                                                <FaSortDown className="text-[18px] text-gray-400" aria-hidden="true" />
                                            </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={ open }
                                            as={ Fragment }
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0">
                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-[16px]">
                                                {meterTypes.length > 0 && meterTypes.map((meter, index) => (
                                                    <Listbox.Option
                                                        key={ index }
                                                        className={ ({ active }) =>
                                                            classNames(
                                                                active ? 'bg-[#599bfb] text-white' : 'text-gray-900',
                                                                'relative cursor-default select-none py-2 pl-3 pr-9 '
                                                            )
                                                        }
                                                        value={ meter.meterName }>
                                                        {({ selectedSecond }) => (
                                                            <>
                                                                <span
                                                                    className={ classNames(
                                                                        selectedSecond ? 'font-semibold' : 'font-normal',
                                                                        'block truncate'
                                                                    ) }>
                                                                    {meter.meterName}
                                                                </span>
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div>

                <div className="flex items-center flex-wrap gap-[24px] mt-[20px]">
                    <p className="text-xl leading-6 font-bold text-[#5a7bad] w-[160px]">
                        Add Type :
                    </p>
                    <input
                        value={ inputValue }
                        onChange={ (e)=> setInputValue(e.target.value) }
                        className='rounded-md bg-white py-1.5 px-4 max-w-[240px] w-full text-left shadow-sm ring-1 ring-inset ring-gray-300 text-xl sm:leading-6 text-[#5a7bad]'
                    />
                    <button
                        className="inline-flex items-center px-4 py-2 border border-transparent text-md font-semibold rounded-md shadow-sm text-white bg-[#599bfb]"
                        onClick={ ()=> addMeterType() }
                    >
                        Add Meter
                    </button>
                </div>

                <div className='flex gap-3'>
                    <button
                        className='mt-[30px] py-[15px] w-[80px] font-semibold rounded-lg bg-[#599bfb] text-white px-[5px] flex justify-center mb-2 text-[20px]'
                        onClick={ ()=> handleAddField() }
                    >
                        <IoMdAddCircleOutline />
                    </button>

                    <button
                        className='mt-[30px] py-[10px] w-[80px] font-semibold rounded-lg bg-[#599bfb] text-white px-[5px] flex justify-center mb-2 text-[18px]'
                        onClick={ () => handleMeterDataSave() }
                    >
                        Save
                    </button>
                </div>

                {formDataList.map((formData, index) => (
                    <div key={ index } className='bg-[#f6fbff] mt-[10px] mb-[10px] w-full p-6 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl space-y-6'>
                        <div className='grid md:grid-cols-10 sm:grid-cols-2 gap-[20px]'>
                            <div className='w-full'>
                                <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">
                                    Start Register
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="start_register_address"
                                        type="number"
                                        value={ formData.start_register_address }
                                        onChange={ (e) => handleInputChange(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className='w-full'>
                                <label htmlFor="name" className="block text-sm font-medium text-[#5a7bad]">
                                    Name
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="name"
                                        type="text"
                                        value={ formData.name }
                                        onChange={ (e) => handleInputChange(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className='w-full'>
                                <label htmlFor="unit" className="block text-sm font-medium text-[#5a7bad]">
                                    Unit
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="unit"
                                        type="text"
                                        value={ formData.unit }
                                        onChange={ (e) => handleInputChange(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className='w-full'>
                                <label htmlFor="type_of_value" className="block text-sm font-medium text-[#5a7bad]">
                                    Type of value
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="type_of_value"
                                        type="text"
                                        value={ formData.type_of_value }
                                        onChange={ (e) => handleInputChange(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className='w-full'>
                                <label htmlFor="no_of_decimals" className="block text-sm font-medium text-[#5a7bad]">
                                    No of decimals
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="no_of_decimals"
                                        type="number"
                                        value={ formData.no_of_decimals }
                                        onChange={ (e) => handleInputChange(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className='w-full'>
                                <label htmlFor="function_code" className="block text-sm font-medium text-[#5a7bad]">
                                    Function code
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="function_code"
                                        type="number"
                                        defaultValue={ 3 }
                                        value={ formData.function_code }
                                        onChange={ (e) => handleInputChange(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className='w-full'>
                                <label htmlFor="function_code" className="block text-sm font-medium text-[#5a7bad]">
                                    No. of Registers
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="no_of_registers"
                                        type="number"
                                        defaultValue={ 2 }
                                        value={ formData.no_of_registers }
                                        onChange={ (e) => handleInputChange(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className='w-full'>
                                <label htmlFor="function_code" className="block text-sm font-medium text-[#5a7bad]">
                                    Signed
                                </label>
                                <div className="mt-1">
                                    <select defaultValue={ 'true' } className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm" value={ formData.signed }
                                        onChange={ (e) => handleInputChange(index, e) } name="signed" id="signed">
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                    </select>
                                </div>
                            </div>
                            <div className='w-full'>
                                <label htmlFor="function_code" className="block text-sm font-medium text-[#5a7bad]">
                                    Byte Order
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="byte_order"
                                        type="number"
                                        defaultValue={ 0 }
                                        value={ formData.byte_order }
                                        onChange={ (e) => handleInputChange(index, e) }
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <button className='font-semibold mt-5' onClick={ () => confirmDeleteHandleRemoveField(index) }>
                                <a className="text-red-500 cursor-pointer">{t('Delete')}</a>
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default IpMeter;
