/* eslint-disable camelcase */
export const Chinese =
    {
        Last_updated: '最近更新時間',
        Dashboard:'儀表板',
        Category:'類別',
        Reports:'報告',
        Devices_Management : '設備管理',
        Viewall : '查看全部',
        Average:'平均',
        Maximum:'最大值',
        Minimum:'最小值',
        Profile:'個人資料',
        Personal_Details :'個人詳細信息',
        Manage_Information : '管理信息在您的帳戶上的顯示方式。',
        Email_address:'電子郵件地址',
        Company:'公司',
        Registration_Date : '註冊日期',
        Password:'密碼',
        Confirm_Password :'確認密碼',
        Save : '保存',
        Download:'下載',
        Download_CSV :'下載 CSV',
        format_csv:'格式.csv',
        Start_Date :'開始日期',
        End_Date :'結束日期',
        Sample_Rate:'採樣率',
        Devices:'設備',
        Gauge:'儀表',
        Export:'導出',
        Notifications:'通知',
        Notification_Condition:'通知条件',
        Notification_Subject:'通知主题',
        Notification_Message:'通知消息',
        Notification_Delay:'首次检测通知延迟（分钟）',
        New_Profile : '新配置文件',
        Company_Name:'公司名稱',
        Cancel : '取消',
        Create_Profile :'創建個人資料',
        Profile_Detalis:'個人資料詳細信息',
        Name:'姓名',
        Whatsapp:'Whatsapp',
        Email:'電子郵件',
        SMS:'短信',
        Edit:'編輯',
        Delete:'刪除',
        Delete_Notificaiton_Description:'您確定要刪除該個人資料嗎？ 您的所有數據都將從我們的服務器中永久刪除。 此操作無法撤消。',
        Create_Device:'創建設備',
        IoT_Host:'物聯網主機',
        IoT_Type:'物聯網類型',
        Device_Name:'設備名稱',
        IoT_ID:'物聯網ID',
        Site:'站點',
        Floors:'樓層',
        Copy:'複製',
        DATA:'數據',
        Copy_Site:'您確定要復制此站點嗎？',
        User_Type:'用戶類型',
        Select_Floor:'選擇樓層',
        Site_Details:'站點詳細信息',
        Refresh_site:'刷新（第二次）',
        Map:'地圖',
        Add_Floor:'添加樓層',
        Create_Floor:'創建樓層',
        Floor_Details:'樓層詳細信息',
        Add_Site:'添加站點',
        Site_Management:'站點管理',
        AutoREFRESH:'自動重新整理',
        Member_Management:'會員管理',
        Add_Member:'添加會員',
        ROLE:'角色',
        STATUS:'狀態',
        Create_Member:'創建會員',
        Sites:'站點',
        Permissions:'權限',
        System_Log:'系統日誌',
        IP_and_Meter_Settings:'IP 和儀表設定',
        Generate_Reports:'報告',
        QOT_MQTT:'MQTT 狀態（即時）',
        SECONDS:'秒數',
        MINUTES:'分鐘',
        DATALOGGER:'Data 記錄器',
        QOTLOGGER:'QOT 記錄器',
        QOTLOGGERHISTORY:'QOT 記錄器歷史記錄',
        TIMESTAMP:'時間戳',
        TYPE:'類型',
        MESSAGE:'消息',
        ENV:'環境',
        View:'查看',
        SITE:'站點',
        Search:'搜索',
        Logout:'註銷',
        Settings:'設置',
        Select_Chart_Type:'選擇圖表類型',
        Card_Type:'卡類型',
        Card_Width:'卡片寬度',
        Card_title:'卡片標題',
        CustomCard_width:'定制卡宽度（vw）',
        CustomCard_height:'自定義卡高度 (vh)',
        Unit_Default:'單位（默認）',
        Order:'訂單',
        Min:'最小值（顯示）',
        Max: '最大值（顯示）',
        Group_As:'組為',
        ShowLatest:'顯示最新',
        ShowDailyHours:'顯示每日使用時間',
        Mode:'模式',
        Group_Name:'組名稱',
        Group:'团体',
        ITEM:'項目',
        ORDER_KEY:'訂單鍵',
        GAUGE:'儀表',
        Add_Device:'添加設備',
        DEVICE:'設備',
        notification_Condition:'通知條件',
        notification_Subject:'通知主題',
        notification_Message:'通知消息',
        notification_Delay:'通知延遲首次檢測（分鐘）',
        Evaluation:'評價',
        Unit:'單位',
        Display_Type:'顯示類型',
        Base_value:'基礎值',
        Max_diff:'獲取最大最小差異',
        PeakKva:'峰值 kVA',
        TodayPeakKva:'今日嘅峰值 kVA',
        accumulative:'閱讀是累積的',
        Remove_value:'刪除零值',
        From_Date:'起始日期',
        To_Date:'截至日期',
        default_line_color:'預設線條顏色',
        Add_Device_Group:'添加設備組',
        Update_Card:'更新卡',
        Download_All_CSV:'下載所有 CSV',
        IpAddress:'IP位址',
        back:'後退',
        SelectCards:'選擇卡片',
        UnSelectCards:'取消選擇卡',
        Confirm:'確認',
        Create_Dashboard:'創建儀表板',
        Edit_Dashboard:'編輯儀表板',
        Dashboard_Details:'儀表板詳細信息',
        Share_With:'分享給',
        Add_new_Dashboard:'添加新儀表板',
        Edit_dashboard:'編輯儀表板',
        Copy_dashboard:'複製儀表板',
        Monthly_Power_Consumption:'本月用電量 ( 由本月一號開始 )',
        COMPANY:'公司',
        EMAIL:'電子郵件',
        FLOORS:'樓層',
        NAME:'名稱',
        true_text:'当真实文本',
        false_text:'当虚假文本',
        true_color:'当真彩色',
        line_color:'線條顏色',
        false_color:'假色时',
        true_text_color:'当真实文本颜色',
        false_text_color:'当错误文本颜色时',
        New_Card:'新卡',
        label:'标签',
        Add_Card:'添加卡',
        edit:'编辑',
        Edit_Chart:'编辑图表',
        copy_word:'您确定要复制此图表吗？',
        qotSelect:'請選擇此 QOT 的儀表板。',
        Select:'選擇',
        From:'从',
        To:'到',
        Edit_Device:'编辑设备',
        IOT_HOST:'物联网主机',
        Delete_Account_des:'您确定要删除您的帐户吗？ 您的所有数据都将从我们的服务器中永久删除。 此操作无法撤消。',
        Add_Devices:'添加设备',
        Add_Sites:'添加站点',
        JEC:'联合电子委员会',
        Exh:'埃克斯',
        Add_device:'添加设备',
        delete_dashboard_des:'您确定要删除仪表板吗？ 您的所有数据都将从我们的服务器中永久删除。 此操作无法撤消。',
        copy_dashbord:'您确定要复制此仪表板吗？',
        Floor:'地面',
        Delete_dashboard:'删除仪表板',
        role_permission:'角色和权限管理',
        All_permissions:'所有权限',
        Add_Role:'添加角色',
        PERMISSION:'允许',
        Action:'行动',
        Create_Notifications:'创建通知',
        Emails:'电子邮件',
        Message:'信息',
        Condition:'健康）状况',
        Device_Selector:'设备选择器',
        send_mail:'发送至选定的电子邮件地址',
        Edit_Profile :'编辑个人资料',
        Demo_Report:'演示报告',
        Report:'报告',
        Admin_Only:'仅限管理员',
        Select_Language:'选择语言',
        Device_Management:'设备管理',
        Create_Site:'创建网站',
        Card_Height:'卡高度',
        Device_Cards:'设备卡',
        Add_Notification:'添加通知',
        Selected:'已選擇'
    }
