import React, { useState, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify'
import { DeviceIcon } from '../../Assets/AllSvg'
import moment from 'moment-timezone';
import { API } from '../../api'
import { io } from 'socket.io-client'
import '@tourmalinecore/react-table-responsive/es/index.css';
import '@tourmalinecore/react-tc-modal/es/index.css';
import '@tourmalinecore/react-tc-ui-kit/es/index.css';
import BarLoader from 'react-spinners/BarLoader';
import { ClientTable } from '@tourmalinecore/react-table-responsive';
const socket = io('wss://system.innovura.app:3000/')
socket.connect()

const QotMqtt = () => {
    const [ datalogs, setDatalogs ] = useState(new Set())
    const [ qotDataLogs, setQotDatalogs ] = useState(new Set())
    const [ loading, setLoading ] = useState(true)
    const [ qotLoggerList, setQotLoggerList ] = useState([])
    const [ selectedQotLogger, setSelectedQotLogger ] = useState([])
    const [ data, setData ] = useState([])
    const [ allData, setAllData ] = useState([])
    const { t } = useTranslation()

    const columns = [
        {
            Header: 'Ping Time',
            accessor: 'pingtime',
        },
        {
            Header: 'Message',
            accessor: 'message',
        }
    ];

    const addQotDataLogs = log =>{
        const newArray = Array.from(qotDataLogs);
        let elementFound = false;
        const updatedArray = newArray.map((item) => {
            if (item.topic === log.topic) {
                // Update the time property of the desired element
                elementFound = true;
                return { ...item, message: log.message };
            }
            return item;
        });
        if (!elementFound) {
            // Add the new data as a new element to the Set
            updatedArray.push(log);
        }
        setQotDatalogs(new Set(updatedArray));
    }

    const removeQotDataLogs = qot =>{
        setQotDatalogs(prev => {
            prev.delete(qot);
            return new Set(prev);
        })
    }

    const addDataLogs = log =>{
        const newArray2 = Array.from(datalogs);
        let elementFound = false;
        const updatedQotArray = newArray2.map((item) => {
            if (item.topic === log.topic) {
                // Update the time property of the desired element
                elementFound = true;
                return { ...item, message: log.message };
            }
            return item;
        });
        if (!elementFound) {
            // Add the new data as a new element to the Set
            updatedQotArray.push(log);
        }
        setDatalogs(new Set(updatedQotArray));
    }

    const removeDataLogs = log =>{
        setDatalogs(prev => {
            prev.delete(log);
            return new Set(prev);
        })
    }
    const removeDuplicates= (list) => {
        const uniqueMessages = new Set();
        const filteredList = [];
        const uniqueMessages2 = new Set();
        const filteredList2 = [];

        for (const item of list) {
            if (!uniqueMessages.has(item.message)) {
                uniqueMessages.add(item.message);
                filteredList.push(item);
            }
        }

        // for (const item of filteredList) {
        //     if (!uniqueMessages2.has(item.message)) {
        //         uniqueMessages2.add(item.message);
        //         filteredList2.push(item);
        //     }
        // }

        return list;
    }
    const fetchQotHistoryData = async () => {
        try {
            setLoading(true)
            const response = await API('/api/logger/list');
            console.log(response)
            setSelectedQotLogger(response.notification[ 0 ].qot)
            const list = []
            const qotLoggerNames = []
            if(response){
                setAllData(response.notification)
                for(const item of response.notification){
                    qotLoggerNames.push(item.qot)
                }
                for(const da of response.notification[ 0 ].data){
                    console.log(moment(da.createdAt).utc().local().format('MM/DD/YYYY, HH:mm:ss.SSS'))
                    list.push({
                        pingtime: moment(da.createdAt).utc().local().format('MM/DD/YYYY, HH:mm:ss.SSS'),
                        message: da.message
                    })
                }
                setQotLoggerList(qotLoggerNames)
                const nonduplicateList = removeDuplicates(list)
                setData(nonduplicateList.reverse())
            }
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        }
    };

    socket.on('connect', () => {
        console.log('connected')
    })

    socket.on('message', (e) => {
        if (e?.topic?.includes('datalogger/')){
            addDataLogs({ topic: e.topic.split('/')[ 1 ], message: (''+e.message).replace('PING', '') })
        }
        else if (e?.topic?.includes('QOTlogger/')){
            addQotDataLogs({ topic: e.topic.split('/')[ 1 ], message: (''+e.message).replace('PING', '') })
        }
    })

    useEffect(() => {
        fetchQotHistoryData();
    }, []);

    useEffect(() => {
        const list = []
        for(const d of allData){
            if (d.qot === selectedQotLogger){
                for(const da of d.data){
                    list.push({
                        pingtime: moment(da.createdAt).utc().local().format('MM/DD/YYYY, HH:mm:ss.SSS'),
                        message: da.message
                    })
                }
            }
        }
        const nonduplicateList = removeDuplicates(list)
        setData(nonduplicateList.reverse())
    }, [selectedQotLogger])

    return (
        <>
            <div>
                <div className="mt-[30px]">
                    <h4 className="text-2xl leading-6 font-bold text-[#5a7bad] mb-5">{t('QOTLOGGERHISTORY')}</h4>
                    {loading && <div className="flex justify-center items-center h-[35vh]">
                        <BarLoader color={ '#5a7bad' } loading={ loading } size={ 150 } />
                    </div> }
                    {!loading && <div className="mt-1 flex">
                        <h6 className="leading-6 font-bold text-[#5a7bad] mt-1 mr-3">{t('QOTLOGGER')} : </h6>
                        <select
                            className="appearance-none block px-3 py-2 border border-gray-300 rounded outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm"
                            value={ selectedQotLogger }
                            onChange={ (e) => setSelectedQotLogger(e.target.value) }>
                            {qotLoggerList.length > 0 && qotLoggerList.map((qotLogger, index) => {
                                return <option key={ index } value={ qotLogger }>{ qotLogger }</option>
                            })}
                        </select>
                    </div>}
                    {!loading && <div className="p-6">
                        <ClientTable
                            style={ { width: '100%' } }
                            tableId="uniq-table-id"
                            data={ data }
                            columns={ columns }
                        />
                    </div>}
                </div>
                <div className="flex gap-2 items-center">
                    <h3 className="text-2xl leading-6 font-bold text-[#5a7bad] ">{t('QOT_MQTT')}</h3>
                </div>
                <div className="mt-[30px]">
                    <h4 className="text-2xl leading-6 font-bold text-[#5a7bad] mb-5">{t('DATALOGGER')}</h4>
                    <div className="grid grid-cols-4 place-content-center">
                        {[...datalogs].sort((a, b) => (''+a.topic).localeCompare(b.topic, 'en', { numeric: true })).map((log, index) => (
                            <div key={ index } className="flex items-center gap-2 p-2"><DeviceIcon/>{ log.topic } : {
                                ((moment()).tz('Asia/Hong_Kong')).diff(moment.utc(log.message, 'MM/DD/YYYY, HH:mm:ss.SSS'), 'seconds')
                            } {t('SECONDS')}</div>
                        ))}
                    </div>
                </div>
                <div className="mt-[30px]">
                    <h4 className="text-2xl leading-6 font-bold text-[#5a7bad] mb-5">{t('QOTLOGGER')}</h4>
                    <div className="grid grid-cols-4 place-content-center">
                        {[...qotDataLogs].sort((a, b) => (''+a.topic).localeCompare(b.topic, 'en', { numeric: true })).map((log, index) => (
                            <div key={ index } className="flex items-center gap-2 p-2"><DeviceIcon/>{ log.topic } : {
                                ((moment()).tz('Asia/Hong_Kong')).diff(moment.utc(log.message, 'MM/DD/YYYY, HH:mm:ss.SSS'), 'seconds')
                            } {t('SECONDS')}</div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default QotMqtt;
