import React, { useEffect, useState, Fragment } from 'react'
import { AiOutlineClose, AiOutlineArrowsAlt } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { API } from '../../../api';
import { toast } from 'react-toastify';
import Linechart from '../../Charts/Linechart';
import BarchartDemo from '../../Charts/BarchartDemo';
import GaugechartDemo from '../../Charts/GaugechartDemo';
import DeviceCard from '../../Charts/DeviceCard';
import StatusCard from '../../Charts/StatusCard';
import ElectricBillCard from '../../Charts/ElectricBillCard';
import { localStorageGet } from '../../../utils/localstorage';
import { CopyIcon, DeviceIcon } from '../../../Assets/AllSvg';
import { useNavigate } from 'react-router'
import { Dialog, Transition } from '@headlessui/react'
import UpdateCard from './UpdateCard';
import { faGear, faClose, faToggleOff, faToggleOn, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../components/Misc/Button';
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import Flatpickr from 'react-flatpickr';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { io } from 'socket.io-client'
const socket = io('wss://system.innovura.app:3000/')
socket.connect()

const Cards = () => {

    const [ dashboard, setDashboard ] = useState('')
    const [ deviceCards, setDeviceCards ] = useState([])
    const [ zoom, setZoom ] = useState('')
    const [ qotMessage, setQotMessage ] = useState('')
    const [ openZoom, setOpenZoom ]= useState(false)
    const [ cards, setCards ] = useState([])
    const [ userPermisiions, setUserPermisiions ] = useState([])
    const [ open1, setOpen1 ] = useState(false)
    const [ show, setShow ] = useState(false)
    const [ selectValue, setSelectValue ] = useState({ name: 'Select Dashboard' })
    const [ dbData, setDbData ] = useState([])
    const [ copyChart, setCopyChart ] = useState('')
    const [ updateTime, setUpdateTime ] = useState(new Date().toLocaleString())
    const [ editChart, setEditChart ] = useState('')
    const [ downloadBtnActive, setDownloadBtnActive ] = useState('')
    const [ refresh, setRefresh ] = useState(false)
    const [ windowDimensions, setWindowDimensions ] = useState(getWindowDimensions());
    const [ width, setWidth ] = useState(window.innerWidth);
    const [ offset, setOffset ] = useState(100);
    const [ selectedCards, setSelectedCards ] = useState([]);
    const [ qotName, setQotName ] = useState(null);
    const [ selectingCards, setSelectingCards ] = useState(false);
    const [ open, setOpen ] = useState(false)
    const [ toDate, setToDate ] = useState('' || localStorage.getItem('toDate'))
    const [ fromDate, setFromDate ] = useState('' || localStorage.getItem('fromDate'))
    const { t } = useTranslation()

    socket.on('connect', () => {
        console.log('connected')
    })

    socket.on('message', (e) => {

        if (e?.topic?.includes('datalogger/')){
            // addDataLogs({ topic: e.topic.split('/')[ 1 ], message: (''+e.message).replace('PING', '') })
        }
        else if (e?.topic?.includes('QOTlogger/'+qotName)){
            setQotMessage({ topic: e.topic.split('/')[ 1 ], message: (''+e.message).replace('PING', '') })
        }
    })

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        setWindowDimensions(getWindowDimensions());
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            console.log(refresh)
            if(refresh){
                setUpdateTime(new Date().toLocaleString())
            }
        }, 10000)
        return () => clearInterval(interval);

    }, [refresh])

    useEffect(() => {
        console.log(selectedCards)
    }, [selectedCards])

    useEffect(() => {
        setRefresh(refresh);
    }, [refresh])

    const userType = localStorageGet('type')
    const ID = location.search.replace('?dashboard_id=', '')
    const navigate = useNavigate()

    const getCards = async () => {
        const response = await API(`/api/list/dashboard/${ ID }`)
        setDashboard(response.dashboard)
        const cards = response.dashboard.cards.filter((val) => (val.type !== 'Device Chart' && val.type !== 'Status Chart' && val.type !== 'Electric Bill Chart'))
        const cardsFilterValue = cards.filter((val) => val?.isDelete === false || true)
        const Devicecards = response.dashboard.cards.filter((val) => (val.type == 'Device Chart' || val.type == 'Status Chart' || val.type == 'Electric Bill Chart'))

        setCards(cardsFilterValue.sort((a, b) => a.order - b.order))
        setDeviceCards(Devicecards.sort((a, b) => a.order - b.order))

        setTimeout(()=>{
            document.querySelectorAll('.eachI').forEach((el) => {
                const outer = el.getBoundingClientRect().height
                const inner = el.closest('div').querySelector('.eachIC').getBoundingClientRect().height + 20
                if (inner !== outer) {
                    el.style.height = `${ inner }px`
                }
            })
        }, 2000)

        if(userType !== 'super-admin') {
            const permissions = localStorageGet('permissions')
            setUserPermisiions(JSON.parse(permissions))
        }
    }
    const getQotEntry = async () => {
        const response = await API(`/api/qot/entry/${ ID }`)
        console.log(response)
        if (response.success){
            setQotName(response.notification.qotName)
        }
    }
    const getUpdateCards = async () => {
        setRefresh(true)
        getCards()
    }

    const deleteCard = async (id) => {
        const allCards = [ ...cards, ...deviceCards ]
        const filterData = allCards.filter((val) => {
            console.log('val._id: '+val._id+'  id: '+id +' : '+(val._id === id))
            if(val._id === id){
                val.isDelete = true
            }
            return val
        })
        console.log(filterData)
        const finalData = {
            method: 'PATCH',
            data : {
                ...dashboard,
                cards: filterData
            }
        }

        const response = await API(`/api/update/dashboard/${ dashboard._id }`, finalData)
        if(response.success){
            toast.error('Card successfully deleted', { autoClose: 5000 })
            getCards()
        }
    }

    const downloadCSVFile = (csv, filename) => {
        var csvFile;
        var downloadLink;

        // CSV file
        csvFile = new Blob([csv], { type: 'text/csv' });

        // Download link
        downloadLink = document.createElement('a');

        // File name
        downloadLink.download = filename;

        // Create a link to the file
        downloadLink.href = window.URL.createObjectURL(csvFile);

        // Hide download link
        downloadLink.style.display = 'none';

        // Add the link to DOM
        document.body.appendChild(downloadLink);

        // Click download link
        downloadLink.click();
    }

    // const downloadCSV = async (val) => {
    //     const data = {
    //         method: 'POST',
    //         data : {
    //             _id : ID,
    //             cardId : val._id,
    //             fromDate: fromDate,
    //             toDate: toDate
    //         }
    //     }
    //     const chartAPI = await API('/chartdata', data)

    //     if(val.type === 'Line Chart'){
    //         const csv = []
    //         const headerRow = [ ' ', chartAPI.chartData.series[ 0 ].yKey +  ' ('+ chartAPI.groupAs+ '  '+chartAPI.mode+')' ]

    //         csv.push(headerRow.join(','));

    //         chartAPI.chartData.series[ 0 ].data.map((date) => {
    //             const row = []
    //             // row.push(moment( ('' + date.time).replace('GMT', '') ).format('DD MMM YYYY HH:mm').toLocaleString())
    //             row.push(moment.utc(('' + date.time).replace('GMT', '')).local().format('DD MMM YYYY HH:mm'))
    //             row.push(date[ chartAPI.chartData.series[ 0 ].yKey ])
    //             csv.push(row.join(','));
    //         })

    //         downloadCSVFile(csv.join('\n'), dashboard.name+'_'+val.title+'_'+'chart_data.csv')
    //     } else if(val.type === 'Bar Chart'){
    //         console.log(chartAPI)
    //         const csv = []
    //         const headerRow = [ ' ', (''+chartAPI.chartData.series[ 0 ].yKey +  ' ('+ chartAPI.groupAs+ '  '+chartAPI.mode+')').replace('(0)', '') ]
    //         // const headerRow = [' ']
    //         csv.push(headerRow.join(','));

    //         chartAPI.chartData.data.map((date) => {
    //             const row = []
    //             // console.log(date)
    //             row.push(moment.utc(('' + date.time).replace('GMT', '')).local().format('DD MMM YYYY HH:mm'))
    //             // row.push(('' + date.time).replace('GMT', ''))
    //             row.push(date[ chartAPI.chartData.series[ 0 ].yKey ])
    //             csv.push(row.join(','));
    //         })

    //         downloadCSVFile(csv.join('\n'), dashboard.name+'_'+val.title+'_'+'chart_data.csv')

    //     }else if(val.type === 'Gauge Chart'){
    //         const csv = []
    //         const headerRow = [ 'Unit', 'percentage' ]
    //         csv.push(headerRow.join(','));

    //         chartAPI.chartData.data.map((val) => {
    //             const row = []
    //             row.push(val.unit)
    //             row.push(val.percentage * 100)
    //             csv.push(row.join(','));
    //         })

    //         downloadCSVFile(csv.join('\n'), dashboard.name+'_'+val.title+'_'+'chart_data.csv')
    //     }
    // }

    const downloadCSVData = async (val) => {
        const data = {
            method: 'POST',
            data : {
                _id : ID,
                cardId : val._id,
                fromDate: fromDate,
                toDate: toDate
            }
        }
        const chartAPI = await API('/chartdata', data)
        return chartAPI
        // if(val.type === 'Line Chart'){
        //     // if (index == 0){
        //     headerRow.push(' ')
        //     headerRow.push(chartAPI.chartData.series[ 0 ].yKey +  ' ('+ chartAPI.groupAs+ '  '+chartAPI.mode+')')
        //     // csv.push(headerRow.join(','));
        //     // }
        //     chartAPI.chartData.series[ 0 ].data.map((date) => {
        //         row.push(moment.utc(('' + date.time).replace('GMT', '')).local().format('DD MMM YYYY HH:mm'))
        //         row.push(date[ chartAPI.chartData.series[ 0 ].yKey ])
        //         // csv.push(row.join(','));
        //     })

        //     return { headerRow, row, name:dashboard.name+'_'+val.title }
        // } else if(val.type === 'Bar Chart'){
        //     // if (index == 0){
        //     headerRow.push(' ')
        //     headerRow.push((''+chartAPI.chartData.series[ 0 ].yKey +  ' ('+ chartAPI.groupAs+ '  '+chartAPI.mode+')').replace('(0)', ''))
        //     chartAPI.chartData.data.map((date) => {
        //         // const row = []
        //         row.push(moment.utc(('' + date.time).replace('GMT', '')).local().format('DD MMM YYYY HH:mm'))
        //         row.push(date[ chartAPI.chartData.series[ 0 ].yKey ])
        //         // csv.push(row.join(','));
        //     })

        //     // downloadCSVFile(csv.join('\n'), dashboard.name+'_'+val.title+'_'+'chart_data.csv')
        //     return { headerRow, row, name: dashboard.name+'_'+val.title }
        // }
    }

    // const downloadAllCSV = () => {
    //     console.log(selectedCards)
    //     if (selectedCards.length > 0)
    //         selectedCards.map(async (val) => {
    //             console.log(val)
    //             downloadCSV(val)
    //         })
    // }

    const downloadAllCSV_ = async () => {
        if (selectedCards.length > 0){
            const csv = []
            let row = []
            let headerRow = []
            const names = []
            for(const [ index, val ] of selectedCards.entries()){
                const r = await downloadCSVData(val, row, headerRow, index)
                console.log(r)
                row = r.row
                headerRow = r.headerRow
                names.push(r.name)
                csv.push(headerRow.join(','));
                csv.push(row.join(','));
            }
            downloadCSVFile(csv.join('\n'), names.join('_')+'_'+'chart_data.csv')
        }
    }

    const downloadAllCSV = async () => {
        if (selectedCards.length > 0){
            const csv = []
            const headerRow = []
            const names = []
            const chartApis = []
            const allRows = []
            for(const [ index, val ] of selectedCards.entries()){
                const r = await downloadCSVData(val)
                chartApis.push(r)
            }

            for(const [ index, chartAPI ] of chartApis.entries()){
                console.log(chartAPI)
                if(selectedCards[ index ].type === 'Line Chart'){

                    for(const [ i, series ] of chartAPI.chartData.series.entries()){
                        headerRow.push(' ')
                        headerRow.push(series.yKey +  ' ('+ chartAPI.groupAs+ '  '+chartAPI.mode+')')
                        // csv.push(headerRow.join(','));
                        const row = []
                        series.data.map((date) => {
                            row.push(moment.utc(('' + date.time).replace('GMT', '')).local().format('DD MMM YYYY HH:mm'))
                            row.push(date[ series.yKey ])
                            // csv.push(row.join(','));
                        })
                        allRows.push(row)
                    }

                    // return { headerRow, row, name:dashboard.name+'_'+selectedCards[ index ].title }
                } else if(selectedCards[ index ].type === 'Bar Chart'){
                    for(const [ i, series ] of chartAPI.chartData.series.entries()){
                        headerRow.push(' ')
                        headerRow.push((''+series.yKey +  ' ('+ chartAPI.groupAs+ '  '+chartAPI.mode+')').replace('(0)', ''))
                        const row = []
                        chartAPI.chartData.data.map((date) => {
                            row.push(moment.utc(('' + date.time).replace('GMT', '')).local().format('DD MMM YYYY HH:mm'))
                            row.push(date[ series.yKey ])
                            // csv.push(row.join(','));
                        })
                        allRows.push(row)
                        // downloadCSVFile(csv.join('\n'), dashboard.name+'_'+selectedCards[index].title+'_'+'chart_data.csv')
                        // return { headerRow, row, name: dashboard.name+'_'+selectedCards[ index ].title }
                    }
                }
            }

            csv.push(headerRow.join(','));
            console.log(allRows)
            const finalRow = []
            for(let i=0; i<allRows[ 0 ].length; i=i+2){
                const row = []
                for(const r of allRows){
                    row.push(r[ i ])
                    row.push(r[ i+1 ])
                }
                finalRow.push(row.join(','))
            }
            csv.push(finalRow.join('\n'));
            downloadCSVFile(csv.join('\n'), names.join('_')+'_'+'chart_data.csv')
        }
    }

    const clearDates = () => {
        localStorage.removeItem('toDate')
        localStorage.removeItem('fromDate')
        location.reload()
    }

    const setDashboardata = async (val) => {
        console.log('setDashboardata val: '+val)
        setOpen1(true);
        setCopyChart(val);
        setSelectValue({ name: 'Select Dashboard' })

        const dropdownData = []
        const sites = await API('/api/site/lists')
        console.log(sites)
        const dashboard = await API('/api/lists/dashboard')
        console.log(dashboard)

        if(userType === 'super-admin'){
            console.log('super-admin')
            sites.sites.map((val) => val.floors.map((flr) => {
                const dboradData = dashboard.dashboard.filter((db) => db.floorId === flr._id && db.siteId === val._id)
                dropdownData.push({ site: `${ val.name } : ${ flr.name }`, id: flr._id, dashboard: dboradData })
            }))
            console.log(dropdownData)
            setDbData(dropdownData)
        } else {
            console.log('!super-admin')
            const sitesData = JSON.parse(localStorageGet('sites'))
            const siteslist = sites.sites.filter((val) => sitesData.includes(val.name))

            siteslist.map((val) => val.floors.map((flr) =>{
                const dboradData = dashboard.dashboard.filter((db) => db.floorId === flr._id && db.siteId === val._id)
                dropdownData.push({ site: `${ val.name } : ${ flr.name }`, id: flr._id, dashboard: dboradData })
            }))
            setDbData(dropdownData)
        }
    }

    const closeSettingsDialog = async () => { setDownloadBtnActive('') }

    const handleCopyChart = async (db) => {
        setSelectValue(db);
        setShow(false)
    }

    const onclickCopyChart = async () => {
        const jsonData = {
            groupAs: copyChart.groupAs,
            groups: copyChart.groups,
            maxValue: copyChart.maxValue,
            minValue: copyChart.minValue,
            mode: copyChart.mode,
            order: copyChart.order,
            title: copyChart.title,
            type: copyChart.type,
            unit: copyChart.unit,
            width: copyChart.width
        }

        const data = {
            method: 'PATCH',
            data : {
                ...selectValue,
                cards: [ ...selectValue.cards, jsonData ]
            }
        }

        const response = await API(`/api/update/dashboard/${ selectValue._id }`, data)
        if(response.success){
            toast.info('Card successfully updated', { autoClose: 5000 })
            navigate(`/dashboard/card?dashboard_id=${ selectValue._id }`)
            setOpen1(false);

            getCards()
        }
    }

    useEffect(() => {
        getCards()
        getQotEntry()
    }, [ID])

    const getOffsetLeft = ( screenX, isMobile ) => {
        // console.log(screenX > (windowDimensions.width/2) ? 'right' : 'left')
        if (isMobile){ setOffset('320') }
        else{
            setOffset(screenX > (windowDimensions.width/2) ? '290' : '0')
        }
    }

    return (
        <div>
            {openZoom ?
                <div className='view-card'>
                    <div key={ zoom._id }
                        onMouseEnter={ () => { setEditChart(zoom) } }
                        style={ { width: zoom.widthType == 'custom' ? `${ zoom.width }vw` : '' } }
                        className={ `flex h-[86vh]
                            ${ (zoom.width === '25%' || zoom.width === '1/4') ? 'w-[25%]' : '' }
                            ${ (zoom.width === '50%' || zoom.width === '2/4') ? 'w-[50%]' : '' }
                            ${ (zoom.width === '75%' || zoom.width === '3/4') ? 'w-[75%]' : '' }
                            ${ (zoom.width === '100%' || zoom.width === 'Full') ? 'w-full' : '' }
                            ${ zoom.width === 'Screen' ? 'w-screen' : '' }
                            ${ zoom.width === 'Max' ? 'w-max' : '' }
                            ${ zoom.width === 'Min' ? 'w-min' : '' }
                            ${ zoom.width === 'Take remaining width' ? 'flex-grow' : '' }
                            gap-4` }>

                        <div className='group relative bg-[#f6fbff] w-full p-2 py-3 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl'>
                            <div className='md:flex items-center gap-6 flex-wrap mb-1 justify-between'>
                                <h4 className='text-[18px] text-[#5a7bad] font-bold'>{zoom.title}</h4>
                                <button onClick={ () => setOpenZoom(false) }>
                                    <AiOutlineClose />
                                </button>
                            </div>

                            {userType === 'super-admin' ?
                                <>
                                    {zoom.type === 'Line Chart' && <div> <Linechart toDate={ toDate } val={ zoom } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } zoom={ true } id={ zoom._id } dashboardId = { ID }/> </div>}
                                    {zoom.type === 'Bar Chart' && <div> <BarchartDemo toDate={ toDate } val={ zoom } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } refresh={ refresh } zoom={ true } id={ zoom._id } dashboardId = { ID } maxWidth={ zoom.maxValue }/>  </div> }
                                    {zoom.type === 'Gauge Chart' && <div> <GaugechartDemo toDate={ toDate } val={ zoom } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ zoom._id } dashboardId = { ID } maxWidth={ zoom.maxValue }/> </div>}
                                    {zoom.type === 'Device Chart' && <div> <DeviceCard toDate={ toDate } val={ zoom } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ zoom._id } dashboardId = { ID }/> </div>}
                                    {zoom.type === 'Electric Bill Chart' && <div> <ElectricBillCard toDate={ toDate } val={ zoom } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ zoom._id } dashboardId = { ID }/> </div>}
                                    {zoom.type === 'Status Chart' && <div> <StatusCard toDate={ toDate } val={ zoom } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ zoom._id } dashboardId = { ID }/> </div>}
                                </>
                                : userPermisiions.includes('View Chart') && <>
                                    {zoom.type === 'Line Chart' && <div> <Linechart toDate={ toDate } val={ zoom } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } zoom={ true } id={ zoom._id } dashboardId = { ID } /> </div>}
                                    {zoom.type === 'Bar Chart' && <div> <BarchartDemo toDate={ toDate } val={ zoom } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } refresh={ refresh } zoom={ true } id={ zoom._id } dashboardId = { ID } maxWidth={ zoom.maxValue }/>  </div> }
                                    {zoom.type === 'Gauge Chart' && <div> <GaugechartDemo toDate={ toDate } val={ zoom } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ zoom._id } dashboardId = { ID } maxWidth={ zoom.maxValue }/> </div>}
                                    {zoom.type === 'Device Chart' && <div> <DeviceCard toDate={ toDate } val={ zoom } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ zoom._id } dashboardId = { ID }/> </div>}
                                    {zoom.type === 'Electric Bill Chart' && <div> <ElectricBillCard toDate={ toDate } val={ zoom } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ zoom._id } dashboardId = { ID }/> </div>}
                                    {zoom.type === 'Status Chart' && <div> <StatusCard toDate={ toDate } val={ zoom } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ zoom._id } dashboardId = { ID }/> </div>}
                                </>}

                            <div className='md:hidden block my-[20px] ml-auto text-end w-[300px]'>
                                { open ? <div className='absolute right-0 z-[100] bg-[#fff] w-[150px] p-[10px] items-center text-end justify-end gap-4 mr-[10px]'>
                                    <div className=' items-center'>
                                        <button className='block ml-auto text-[#5a7bad] pb-[3px] font-semibold '>{t('Settings')}</button>
                                        <button onClick={ () => downloadCSV(zoom) } className='ml-auto block text-[#5a7bad] font-semibold '>{t('Download_CSV')}
                                        </button>

                                        {userType === 'super-admin' ? <Link to={ `/card/new?id=${ ID }&card_id=${ zoom._id }` } className='text-[#599bfb] font-medium'>{t('edit')}</Link>
                                            : userPermisiions.includes('Edit Chart') && <Link to={ `/card/new?id=${ ID }&card_id=${ zoom._id }` } className='text-[#599bfb] font-medium'>{t('edit')}</Link>}
                                        {userType === 'super-admin' ? <button onClick={ () => deleteCard(zoom._id) } className='w-fit ml-auto py-[3px] justify-end flex transition-all text-[#ff6262] font-semibold rounded-md flex justify-end'>Delete</button>
                                            : userPermisiions.includes('Delete Chart') && <button onClick={ () => deleteCard(zoom._id) } className='  w-fit py-[3px] transition-all text-[#ff6262] font-semibold rounded-md flex mx-auto justify-end'>Delete</button>}
                                    </div>
                                </div> : ''}
                            </div>
                            <ul
                                // onMouseLeave={ (e) => setDownloadBtnActive('') }
                                className={ cn('w-max h-max bg-white rounded-sm text-center p-3 flex items-center gap-3') }>
                                {userType === 'super-admin' && <li>
                                    <button onClick={ () => { setDashboardata(zoom); setDownloadBtnActive('') } } className='text-[#fff] text-[14px] rounded-md py-[1px] px-[10px] bg-[#599bfb] font-medium'>{t('Copy')}</button>
                                </li>}
                                <li>
                                    { ((editChart._id !== zoom._id ) && (zoom.type !== 'Device Chart' && zoom.type !== 'Electric Bill Chart') && zoom.width == '1/4') && <button onClick={ () => setEditChart(zoom) } className='text-[#599bfb] font-semibold'>
                                        {t('Edit_Chart')}
                                    </button>}
                                </li>
                                <li>
                                    {userType === 'super-admin' ?
                                        <Link to={ `/card/new?id=${ ID }&card_id=${ zoom._id }` } className='text-[#599bfb] font-semibold'>{t('Settings')}</Link> :
                                        userPermisiions.includes('Edit Chart') && <Link to={ `/card/new?id=${ ID }&card_id=${ zoom._id }` } className='text-[#599bfb] font-semibold'>{t('Settings')}</Link>}
                                </li>
                                <li>
                                    {userType === 'super-admin' ? <button onClick={ () => deleteCard(zoom._id) } className='text-[#ff6262] font-semibold'>{t('Delete')}</button>
                                        : userPermisiions.includes('Delete Chart') && <button onClick={ () => deleteCard(zoom._id) } className='text-[#ff6262] font-semibold'>{t('Delete')}</button>}
                                </li>
                                {(zoom.type !== 'Device Chart' && zoom.type !== 'Electric Bill Chart') && <li>
                                    <button onClick={ () => downloadCSV(zoom) } className='text-[#5a7bad] font-semibold'>{t('Download_CSV')}
                                    </button>
                                </li>}
                                <li className='chart-data'>
                                    {editChart._id === zoom._id && <UpdateCard getCards={ getCards } getUpdateCards={ getUpdateCards } dashboard={ dashboard } editChart={ editChart } setDownloadBtnActive={ setDownloadBtnActive } />}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> :
                <div>
                    <div className='flex sm:flex-row flex-col items-center gap-4'>
                        <button onClick={ (e)=>{ window.history.go(-1); return false; } } className='w-fit py-[4px] px-[20px] border-[2px] border-[#599bfb] text-[#599bfb] transition-all font-semibold rounded-md flex justify-end'>{t('back')}</button>
                        <h3 className="text-xl leading-6 font-bold text-[#5a7bad]">
                            {dashboard.name} ({ cards[ 0 ]?.groups[ 0 ]?.devices[ 0 ]?.iot_host })
                        </h3>
                        <div className='flex gap-4 flex-wrap'>
                            {userType === 'super-admin' ? <Link to={ `/card/new?id=${ ID }&` } className='w-fit py-[4px] px-[20px] border-[2px] bg-[#5a7bad] transition-all text-white font-semibold rounded-md flex justify-end'>{t('Add_Card')}</Link>
                                : userPermisiions.includes('Add Chart')
                        && <Link to={ `/card/new?id=${ ID }&` } className='w-fit py-[4px] px-[20px] border-[2px] bg-[#5a7bad] transition-all text-white font-semibold rounded-md flex justify-end'>{t('Add_Card')}</Link>}
                            {userType === 'super-admin' ? <Link to={ `/dashboard/new?site_id=${ dashboard.siteId }&floor_id=${ dashboard.floorId }&dashboard_id=${ dashboard._id }` } className='w-fit py-[4px] px-[20px] border-[2px] bg-[#599bfb] transition-all text-white ont-semibold rounded-md flex justify-end'>{t('Edit')}</Link>
                                : userPermisiions.includes('Edit Dashboard')
                        && <Link to={ `/dashboard/new?site_id=${ dashboard.siteId }&floor_id=${ dashboard.floorId }&dashboard_id=${ dashboard._id }` } className='w-fit py-[4px] px-[20px] border-[2px] bg-[#599bfb] transition-all text-white ont-semibold rounded-md flex justify-end'>{t('Edit')}</Link>}

                            {userType !== 'user' && !selectingCards && <button onClick={ (e)=>{ setSelectingCards(!selectingCards) } } className='w-fit py-[4px] px-[20px] border-[2px] border-[#599bfb] text-[#599bfb] transition-all font-semibold rounded-md flex justify-end'>{t('SelectCards')}</button>}
                            {userType !== 'user' && selectingCards && <button onClick={ (e)=>{ setSelectingCards(!selectingCards); setSelectedCards([]); } } className='w-fit py-[4px] px-[20px] border-[2px] border-[#599bfb] text-[#599bfb] transition-all font-semibold rounded-md flex justify-end'>{t('UnSelectCards')}</button>}
                            {userType !== 'user' && <button onClick={ downloadAllCSV } className='w-fit py-[4px] px-[20px] border-[2px] border-[#599bfb] text-[#599bfb] transition-all font-semibold rounded-md flex justify-end'>{t('Download_All_CSV')}</button>}
                            <h3 className='text-l leading-6 mt-2 font-bold text-[#5a7bad]'>{t('Last_updated')} : {updateTime}</h3>

                            {/* MQTT QOT status */}
                            {qotMessage?.topic && qotMessage?.message && <div className="flex mt-1 items-center gap-2">
                                <span className='text-l leading-6 font-bold text-[#5a7bad]'>QOT</span>
                                <FontAwesomeIcon
                                    icon={ faLightbulb }
                                    size='l'
                                    style={ { color: 'green' } }
                                />
                                {
                                    ((moment()).tz('Asia/Hong_Kong')).diff(moment.utc(qotMessage?.message, 'MM/DD/YYYY, HH:mm:ss'), 'minutes')
                                } {t('MINUTES')}</div>}

                            <Button variant='primary'
                                className='-mb-1'
                                onClick={ ()=>{
                                    console.log(!refresh)
                                    setRefresh(!refresh);
                                } }
                            >
                                <h3 className='text-l leading-6 font-bold text-[#5a7bad]'>{t('AutoREFRESH')}</h3>
                                {refresh && <FontAwesomeIcon
                                    icon={ faToggleOn }
                                    size='xl'
                                    style={ { color: 'skyblue' } }
                                />}
                                {!refresh && <FontAwesomeIcon
                                    icon={ faToggleOff }
                                    size='xl'
                                    style={ { color: 'gray' } }
                                />}
                            </Button>
                        </div>
                    </div>
                    <div>
                        {/* Device Card And Status Card  */}
                        {!isMobile && <div className='flex gap-[20px] flex-wrap mt-[10px]'>
                            {deviceCards?.map((val) =>
                                <div key={ val._id }
                                    onMouseEnter={ () => { setEditChart(val) } }
                                    style={ { width: val.widthType == 'custom' ? `${ val.width }vw` : '' } }
                                    className={ `flex h-fit
                            ${ (val.width === '25%' || val.width === '1/4') ? 'w-[25%]' : '' }
                            ${ (val.width === '50%' || val.width === '2/4') ? 'w-[50%]' : '' }
                            ${ (val.width === '75%' || val.width === '3/4') ? 'w-[75%]' : '' }
                            ${ (val.width === '100%' || val.width === 'Full') ? 'w-full' : '' }
                            ${ val.width === 'Screen' ? 'w-screen' : '' }
                            ${ val.width === 'Max' ? 'w-max' : '' }
                            ${ val.width === 'Min' ? 'w-min' : '' }
                            ${ val.width === 'Take remaining width' ? 'flex-grow' : '' }
                            gap-4` }>
                                    <div className='group relative bg-[#f6fbff] w-full p-2 py-3 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl'>
                                        <div>
                                            <div className='md:flex items-center gap-6 flex-wrap mb-1'>
                                                <h4 className='text-[18px] text-[#5a7bad] font-bold'>{val.title}</h4>
                                            </div>
                                        </div>

                                        {userType === 'super-admin' ?
                                            <>
                                                {val.type === 'Line Chart' && <div> <Linechart toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Bar Chart' && <div> <BarchartDemo toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } refresh={ refresh } id={ val._id } dashboardId = { ID } maxWidth={ val.maxValue }/>  </div> }
                                                {val.type === 'Gauge Chart' && <div> <GaugechartDemo toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID } maxWidth={ val.maxValue }/> </div>}
                                                {val.type === 'Device Chart' && <div> <DeviceCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Electric Bill Chart' && <div> <ElectricBillCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Status Chart' && <div> <StatusCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                            </>
                                            : userPermisiions.includes('View Chart') && <>
                                                {val.type === 'Line Chart' && <div> <Linechart toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID } /> </div>}
                                                {val.type === 'Bar Chart' && <div> <BarchartDemo toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } refresh={ refresh } id={ val._id } dashboardId = { ID } maxWidth={ val.maxValue }/>  </div> }
                                                {val.type === 'Gauge Chart' && <div> <GaugechartDemo toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID } maxWidth={ val.maxValue }/> </div>}
                                                {val.type === 'Device Chart' && <div> <DeviceCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Electric Bill Chart' && <div> <ElectricBillCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Status Chart' && <div> <StatusCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                            </>}

                                        <div className='md:hidden block my-[20px] ml-auto text-end w-[300px]'>
                                            {/* <div className='mt-[60px]'>
                                        <button onClick={ ()=>setOpen(!open) }>
                                            <RiArrowDownSLine className='text-[20px]'/>
                                        </button>
                                    </div> */}
                                            { open ? <div className='absolute right-0 z-[100] bg-[#fff] w-[150px] p-[10px] items-center text-end justify-end gap-4 mr-[10px]'>
                                                <div className=' items-center'>
                                                    <button className='block ml-auto text-[#5a7bad] pb-[3px] font-semibold '>{t('Settings')}</button>
                                                    <button onClick={ () => downloadCSV(val) } className='ml-auto block text-[#5a7bad] font-semibold '>{t('Download_CSV')}
                                                    </button>

                                                    {userType === 'super-admin' ? <Link to={ `/card/new?id=${ ID }&card_id=${ val._id }` } className='text-[#599bfb] font-medium'>{t('edit')}</Link>
                                                        : userPermisiions.includes('Edit Chart') && <Link to={ `/card/new?id=${ ID }&card_id=${ val._id }` } className='text-[#599bfb] font-medium'>{t('edit')}</Link>}
                                                    {userType === 'super-admin' ? <button onClick={ () => deleteCard(val._id) } className='w-fit ml-auto py-[3px] justify-end flex transition-all text-[#ff6262] font-semibold rounded-md flex justify-end'>{t('Delete')}</button>
                                                        : userPermisiions.includes('Delete Chart') && <button onClick={ () => deleteCard(val._id) } className='  w-fit py-[3px] transition-all text-[#ff6262] font-semibold rounded-md flex mx-auto justify-end'>{t('Delete')}</button>}
                                                </div>
                                            </div> : ''}
                                        </div>
                                        <div className='flex absolute right-[0px] top-[15px]'>
                                            <div className="mr-3 w-full z-5000">
                                                <div className='grid grid-cols-2'>
                                                    <Button variant='primary'
                                                        onClick={ (e) => {
                                                            getOffsetLeft(e.screenX, true)
                                                            downloadBtnActive == val._id ? setDownloadBtnActive('') : setDownloadBtnActive(val._id);
                                                        } }
                                                        className={ (downloadBtnActive === val._id) ? 'hover:!bg-opacity-100' : '' }>

                                                        <FontAwesomeIcon
                                                            icon={ faGear }
                                                            style={ { color: 'gray' } }
                                                            className='-mt-1'
                                                        />
                                                    </Button>
                                                    <button onClick={ () =>  {setZoom(val), setOpenZoom(true)} } className='text-[24px]'>
                                                        <AiOutlineArrowsAlt/>
                                                    </button>
                                                </div>
                                                <ul
                                                    // onMouseLeave={ (e) => setDownloadBtnActive('') }
                                                    style={ { marginLeft: `-${ offset }px`, zIndex:'100' } }
                                                    className={ cn('absolute top-[calc(100%+0.5rem)] w-max h-max bg-white shadow-md rounded-sm text-center p-3', {
                                                        'visible pointer-events-auto': (downloadBtnActive === val._id),
                                                        'invisible pointer-events-none': !(downloadBtnActive === val._id)
                                                    }) }>
                                                    <li className='text-right'>
                                                        <FontAwesomeIcon
                                                            icon={ faClose }
                                                            onClick={ () => setDownloadBtnActive('') }
                                                            style={ { color: 'gray' } }
                                                            className={ cn({
                                                                'rotate-180' : !(downloadBtnActive === val._id) ? true : false
                                                            }) }
                                                        />
                                                    </li>
                                                    {userType === 'super-admin' && <li>
                                                        <button onClick={ () => {setDashboardata(val); setDownloadBtnActive('') } } className='text-[#fff] text-[14px] rounded-md py-[1px] px-[10px] bg-[#599bfb] font-medium mb-3'>{t('Copy')}</button>
                                                    </li>}
                                                    <li>
                                                        { ((editChart._id !== val._id ) && (val.type !== 'Device Chart' && val.type !== 'Electric Bill Chart') && val.width == '1/4') && <button onClick={ () => setEditChart(val) } className='text-[#599bfb] font-semibold mb-2'>
                                                            {t('Edit_Chart')}
                                                        </button>}
                                                    </li>
                                                    <li>
                                                        {userType === 'super-admin' ?
                                                            <Link to={ `/card/new?id=${ ID }&card_id=${ val._id }` } className='text-[#599bfb] font-semibold mb-2'>{t('Settings')}</Link> :
                                                            userPermisiions.includes('Edit Chart') && <Link to={ `/card/new?id=${ ID }&card_id=${ val._id }` } className='text-[#599bfb] font-semibold mb-2'>{t('Settings')}</Link>}
                                                    </li>
                                                    <li>
                                                        {userType === 'super-admin' ? <button onClick={ () => deleteCard(val._id) } className='text-[#ff6262] font-semibold mb-2 mt-2'>{t('Delete')}</button>
                                                            : userPermisiions.includes('Delete Chart') && <button onClick={ () => deleteCard(val._id) } className='text-[#ff6262] font-semibold mb-2 mt-2'>{t('Delete')}</button>}
                                                    </li>
                                                    {(val.type !== 'Device Chart' && val.type !== 'Electric Bill Chart') && <li>
                                                        <button onClick={ () => downloadCSV(val) } className='text-[#5a7bad] font-semibold mb-2'>{t('Download_CSV')}
                                                        </button>
                                                    </li>}
                                                    <li>
                                                        {editChart._id === val._id && <UpdateCard getCards={ getCards } getUpdateCards={ getUpdateCards } setDownloadBtnActive={ setDownloadBtnActive } dashboard={ dashboard } editChart={ editChart }/>}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>

                                </div>)}
                            <div>
                            </div>
                        </div>}
                        {isMobile && <div className='flex gap-[20px] flex-wrap mt-[10px]'>
                            {deviceCards?.map((val) =>
                                <div key={ val._id }
                                    onMouseEnter={ () => { setEditChart(val) } }
                                    className={ 'flex w-full gap-4 h-fit' }>

                                    <div className='group relative bg-[#f6fbff] w-full p-2 py-3 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl'>
                                        <div className='md:flex items-center gap-6 flex-wrap mb-1'>
                                            <h4 className='text-[18px] text-[#5a7bad] font-bold'>{val.title}</h4>
                                        </div>

                                        {userType === 'super-admin' ?
                                            <>
                                                {val.type === 'Line Chart' && <div> <Linechart toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Bar Chart' && <div> <BarchartDemo toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } refresh={ refresh } id={ val._id } dashboardId = { ID } maxWidth={ val.maxValue }/>  </div> }
                                                {/* {val.type === 'Bar Chart' && <div> <Barchart id={ val._id } dashboardId = { ID } />  </div> } */}
                                                {val.type === 'Gauge Chart' && <div> <GaugechartDemo toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID } maxWidth={ val.maxValue }/> </div>}
                                                {val.type === 'Device Chart' && <div> <DeviceCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Electric Bill Chart' && <div> <ElectricBillCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Status Chart' && <div> <StatusCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                            </>
                                            : userPermisiions.includes('View Chart') && <>
                                                {val.type === 'Line Chart' && <div> <Linechart toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID } /> </div>}
                                                {val.type === 'Bar Chart' && <div> <BarchartDemo toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } refresh={ refresh } id={ val._id } dashboardId = { ID } maxWidth={ val.maxValue }/>  </div> }
                                                {val.type === 'Gauge Chart' && <div> <GaugechartDemo toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID } maxWidth={ val.maxValue }/> </div>}
                                                {val.type === 'Device Chart' && <div> <DeviceCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Electric Bill Chart' && <div> <ElectricBillCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Status Chart' && <div> <StatusCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                            </>}

                                        <div className='md:hidden block my-[20px] ml-auto text-end w-[300px]'>
                                            {/* <div className='mt-[60px]'>
                                        <button onClick={ ()=>setOpen(!open) }>
                                            <RiArrowDownSLine className='text-[20px]'/>
                                        </button>
                                    </div> */}
                                            { open ? <div className='absolute right-0 z-[100] bg-[#fff] w-[150px] p-[10px] items-center text-end justify-end gap-4 mr-[10px]'>
                                                <div className=' items-center'>
                                                    <button className='block ml-auto text-[#5a7bad] pb-[3px] font-semibold '>{t('Settings')}</button>
                                                    <button onClick={ () => downloadCSV(val) } className='ml-auto block text-[#5a7bad] font-semibold '>{t('Download_CSV')}
                                                    </button>

                                                    {userType === 'super-admin' ? <Link to={ `/card/new?id=${ ID }&card_id=${ val._id }` } className='text-[#599bfb] font-medium'>{t('edit')}</Link>
                                                        : userPermisiions.includes('Edit Chart') && <Link to={ `/card/new?id=${ ID }&card_id=${ val._id }` } className='text-[#599bfb] font-medium'>{t('edit')}</Link>}
                                                    {userType === 'super-admin' ? <button onClick={ () => deleteCard(val._id) } className='w-fit ml-auto py-[3px] justify-end flex transition-all text-[#ff6262] font-semibold rounded-md flex justify-end'>{t('Delete')}</button>
                                                        : userPermisiions.includes('Delete Chart') && <button onClick={ () => deleteCard(val._id) } className='  w-fit py-[3px] transition-all text-[#ff6262] font-semibold rounded-md flex mx-auto justify-end'>{t('Delete')}</button>}
                                                </div>
                                            </div> : ''}
                                        </div>
                                        <div className='flex absolute right-[0px] top-[15px]'>
                                            <div className="mr-3 w-full z-5000">
                                                <div className='grid grid-cols-2'>
                                                    <Button variant='primary'
                                                        onClick={ (e) => {
                                                            getOffsetLeft(e.screenX, true)
                                                            downloadBtnActive == val._id ? setDownloadBtnActive('') : setDownloadBtnActive(val._id);
                                                        } }
                                                        className={ (downloadBtnActive === val._id) ? 'hover:!bg-opacity-100' : '' }>

                                                        <FontAwesomeIcon
                                                            icon={ faGear }
                                                            style={ { color: 'gray' } }
                                                            className='-mt-1'
                                                        />
                                                    </Button>
                                                    <button onClick={ () =>  {setZoom(val), setOpenZoom(true)} } className='text-[24px]'>
                                                        <AiOutlineArrowsAlt/>
                                                    </button>
                                                </div>
                                                <ul
                                                    // onMouseLeave={ () => setDownloadBtnActive('') }
                                                    style={ { marginLeft: `-${ offset }px`, zIndex:'100' } }
                                                    className={ cn('absolute top-[calc(100%+0.5rem)] -ml-[100px] w-max h-max bg-white shadow-md rounded-sm text-center p-3', {
                                                        'visible pointer-events-auto': (downloadBtnActive === val._id),
                                                        'invisible pointer-events-none': !(downloadBtnActive === val._id)
                                                    }) }>
                                                    <li className='text-right'>
                                                        <FontAwesomeIcon
                                                            icon={ faClose }
                                                            onClick={ () => {setDownloadBtnActive('') } }
                                                            style={ { color: 'gray' } }
                                                            className={ cn({
                                                                'rotate-180' : !(downloadBtnActive === val._id) ? true : false
                                                            }) }
                                                        />
                                                    </li>
                                                    {userType === 'super-admin' && <li>
                                                        <button onClick={ () =>{ setDashboardata(val); setDownloadBtnActive('') } } className='text-[#fff] text-[14px] rounded-md py-[1px] px-[10px] bg-[#599bfb] font-medium mb-3'>{t('Copy')}</button>
                                                    </li>}
                                                    <li>
                                                        { ((editChart._id !== val._id ) && (val.type !== 'Device Chart' && val.type !== 'Electric Bill Chart') && val.width == '1/4') && <button onClick={ () => setEditChart(val) } className='text-[#599bfb] font-semibold mb-2'>
                                                            {t('Edit_Chart')}
                                                        </button>}
                                                    </li>
                                                    <li>
                                                        {userType === 'super-admin' ?
                                                            <Link to={ `/card/new?id=${ ID }&card_id=${ val._id }` } className='text-[#599bfb] font-semibold mb-2'>{t('Settings')}</Link> :
                                                            userPermisiions.includes('Edit Chart') && <Link to={ `/card/new?id=${ ID }&card_id=${ val._id }` } className='text-[#599bfb] font-semibold mb-2'>{t('Settings')}</Link>}
                                                    </li>
                                                    <li>
                                                        {userType === 'super-admin' ? <button onClick={ () => deleteCard(val._id) } className='text-[#ff6262] font-semibold mb-2 mt-2'>{t('Delete')}</button>
                                                            : userPermisiions.includes('Delete Chart') && <button onClick={ () => deleteCard(val._id) } className='text-[#ff6262] font-semibold mb-2 mt-2'>{t('Delete')}</button>}
                                                    </li>
                                                    {(val.type !== 'Device Chart' && val.type !== 'Electric Bill Chart') && <li>
                                                        <button onClick={ () => downloadCSV(val) } className='text-[#5a7bad] font-semibold mb-2'>{t('Download_CSV')}
                                                        </button>
                                                    </li>}
                                                    <li>
                                                        {editChart._id === val._id && <UpdateCard getCards={ getCards } getUpdateCards={ getUpdateCards } dashboard={ dashboard } editChart={ editChart } setDownloadBtnActive={ setDownloadBtnActive } />}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>)}
                            <div>
                            </div>
                        </div>}
                        {/* Other Cards */}
                        {!isMobile && <div className='flex gap-[20px] flex-wrap mt-[10px]'>
                            {cards?.map((val) =>
                                <div key={ val._id }
                                    onMouseEnter={ () => { setEditChart(val) } }
                                    style={ { width: val.widthType == 'custom' ? `${ val.width }vw` : '', height: val.heightType == 'custom' ? `${ val.height }vw` : '' } }
                                    className={ `flex

                                    ${ (val.height === '25%' || val.height === '1/4') ? 'h-[25%]' : '' }
                                    ${ (val.height === '50%' || val.height === '2/4') ? 'h-[50%]' : '' }
                                    ${ (val.height === '75%' || val.height === '3/4') ? 'h-[75%]' : '' }
                                    ${ (val.height === '100%' || val.height === 'Full') ? 'h-full' : '' }
                                    ${ val.height === 'Screen' ? 'h-screen' : '' }
                                    ${ val.height === 'Max' ? 'h-max' : '' }
                                    ${ val.height === 'Min' ? 'h-min' : '' }
                                    ${ val.height === 'Take remaining height' ? 'flex-grow' : '' }

                                    ${ (val.width === '25%' || val.width === '1/4') ? 'w-[25%]' : '' }
                                    ${ (val.width === '50%' || val.width === '2/4') ? 'w-[50%]' : '' }
                                    ${ (val.width === '75%' || val.width === '3/4') ? 'w-[75%]' : '' }
                                    ${ (val.width === '100%' || val.width === 'Full') ? 'w-full' : '' }
                                    ${ val.width === 'Screen' ? 'w-screen' : '' }
                                    ${ val.width === 'Max' ? 'w-max' : '' }
                                    ${ val.width === 'Min' ? 'w-min' : '' }
                                    ${ val.width === 'Take remaining width' ? 'flex-grow' : '' }
                                    gap-4` }>

                                    <div className='group relative bg-[#f6fbff] w-full p-2 py-3 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl'>
                                        <div className='md:flex items-center gap-6 flex-wrap mb-1'>
                                            <h4 className='text-[18px] text-[#5a7bad] font-bold'>{val.title}</h4>
                                            <div className='w-max align-middle flex flex-row mb-1'>
                                                <label htmlFor="date" className="block text-sm font-medium text-[#5a7bad] mr-3 mt-3">
                                                    {t('From')}
                                                </label>
                                                <div className="mt-1 relative w-fit" style={ { zIndex:'999999' } }>
                                                    <Flatpickr
                                                        style={ { zIndex:'999999' } }
                                                        className='w-full rounded-[8px] border-0 bg-transparent text-sm'
                                                        value={ fromDate }
                                                        placeholder="mm/dd/yyyy"
                                                        onChange={ (date) =>  {

                                                            const fromDate = moment((''+new Date(date).toISOString()).replace('T', ' ').replace('Z', ' ')).add('1', 'days').toISOString()

                                                            setFromDate(fromDate)
                                                            localStorage.setItem('fromDate', fromDate)

                                                        } }
                                                    />
                                                </div>
                                            </div>
                                            <div className='w-max align-middle flex flex-row mb-1'>
                                                <label htmlFor="date" className="block text-sm font-medium text-[#5a7bad]  mr-3 mt-3">
                                                    {t('To')}&nbsp;&nbsp;&nbsp;&nbsp;
                                                </label>
                                                <div className="mt-1 relative w-fit" style={ { zIndex:'999999' } }>
                                                    <Flatpickr
                                                        style={ { zIndex:'999999' } }
                                                        value={ toDate }
                                                        className='w-full rounded-[8px] border-0 bg-transparent text-sm'
                                                        placeholder="mm/dd/yyyy"
                                                        onChange={ (date) => {

                                                            const toDate = moment((''+new Date(date).toISOString()).replace('T', ' ').replace('Z', ' ')).add('1', 'days').toISOString()

                                                            setToDate(toDate)
                                                            localStorage.setItem('toDate', toDate)
                                                        } }
                                                    />
                                                </div>
                                            </div>
                                            {localStorage.getItem('toDate') !== null && <div className='w-max align-middle flex flex-row text-gray-500 text-sm'>
                                                <button onClick={ () => { clearDates() } }>Clear Date</button>
                                            </div>}
                                        </div>

                                        {userType === 'super-admin' ?
                                            <>
                                                {val.type === 'Line Chart' && <div> <Linechart toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Bar Chart' && <div> <BarchartDemo toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } refresh={ refresh } id={ val._id } dashboardId = { ID } maxWidth={ val.maxValue }/>  </div> }
                                                {val.type === 'Gauge Chart' && <div> <GaugechartDemo toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID } maxWidth={ val.maxValue }/> </div>}
                                                {val.type === 'Device Chart' && <div> <DeviceCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Electric Bill Chart' && <div> <ElectricBillCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Status Chart' && <div> <StatusCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                            </>
                                            : userPermisiions.includes('View Chart') && <>
                                                {val.type === 'Line Chart' && <div> <Linechart toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID } /> </div>}
                                                {val.type === 'Bar Chart' && <div> <BarchartDemo toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } refresh={ refresh } id={ val._id } dashboardId = { ID } maxWidth={ val.maxValue }/>  </div> }
                                                {val.type === 'Gauge Chart' && <div> <GaugechartDemo toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID } maxWidth={ val.maxValue }/> </div>}
                                                {val.type === 'Device Chart' && <div> <DeviceCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Electric Bill Chart' && <div> <ElectricBillCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Status Chart' && <div> <StatusCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                            </>}

                                        <div className='md:hidden block my-[20px] ml-auto text-end w-[300px]'>
                                            {/* <div className='mt-[60px]'>
                                        <button onClick={ ()=>setOpen(!open) }>
                                            <RiArrowDownSLine className='text-[20px]'/>
                                        </button>
                                    </div> */}
                                            { open ? <div className='absolute right-0 z-[100] bg-[#fff] w-[150px] p-[10px] items-center text-end justify-end gap-4 mr-[10px]'>
                                                <div className=' items-center'>
                                                    <button className='block ml-auto text-[#5a7bad] pb-[3px] font-semibold '>{t('Settings')}</button>
                                                    <button onClick={ () => downloadCSV(val) } className='ml-auto block text-[#5a7bad] font-semibold '>{t('Download_CSV')}
                                                    </button>

                                                    {userType === 'super-admin' ? <Link to={ `/card/new?id=${ ID }&card_id=${ val._id }` } className='text-[#599bfb] font-medium'>{t('edit')}</Link>
                                                        : userPermisiions.includes('Edit Chart') && <Link to={ `/card/new?id=${ ID }&card_id=${ val._id }` } className='text-[#599bfb] font-medium'>{t('edit')}</Link>}
                                                    {userType === 'super-admin' ? <button onClick={ () => deleteCard(val._id) } className='w-fit ml-auto py-[3px] justify-end flex transition-all text-[#ff6262] font-semibold rounded-md flex justify-end'>Delete</button>
                                                        : userPermisiions.includes('Delete Chart') && <button onClick={ () => deleteCard(val._id) } className='  w-fit py-[3px] transition-all text-[#ff6262] font-semibold rounded-md flex mx-auto justify-end'>Delete</button>}
                                                </div>
                                            </div> : ''}
                                        </div>
                                        <div className='flex absolute right-[0px] top-[8px]'>
                                            <div className="mr-3 w-full z-5000">
                                                <div className='grid grid-cols-2'>
                                                    <Button variant='primary'
                                                        onClick={ (e) => {
                                                            getOffsetLeft(e.screenX, true)
                                                            downloadBtnActive == val._id ? setDownloadBtnActive('') : setDownloadBtnActive(val._id);
                                                        } }
                                                        className={ (downloadBtnActive === val._id) ? 'hover:!bg-opacity-100' : '' }>

                                                        <FontAwesomeIcon
                                                            icon={ faGear }
                                                            style={ { color: 'gray' } }
                                                            className='-mt-1'
                                                        />
                                                    </Button>
                                                    <button onClick={ () =>  {setZoom(val), setOpenZoom(true)} } className='text-[24px]'>
                                                        <AiOutlineArrowsAlt/>
                                                    </button>
                                                </div>
                                                <ul
                                                    // onMouseLeave={ () => setDownloadBtnActive('') }
                                                    style={ { marginLeft: `-${ offset }px`, zIndex:'100' } }
                                                    className={ cn('absolute top-[calc(100%+0.5rem)] w-max h-max bg-white shadow-md rounded-sm text-center p-3', {
                                                        'visible pointer-events-auto': (downloadBtnActive === val._id),
                                                        'invisible pointer-events-none': !(downloadBtnActive === val._id)
                                                    }) }>
                                                    <li className='text-right'>
                                                        <FontAwesomeIcon
                                                            icon={ faClose }
                                                            onClick={ () => {setDownloadBtnActive('') } }
                                                            style={ { color: 'gray' } }
                                                            className={ cn({
                                                                'rotate-180' : !(downloadBtnActive === val._id) ? true : false
                                                            }) }
                                                        />
                                                    </li>
                                                    {userType === 'super-admin' && <li>
                                                        <button onClick={ () =>{ setDashboardata(val); setDownloadBtnActive('') } } className='text-[#fff] text-[14px] rounded-md py-[1px] px-[10px] bg-[#599bfb] font-medium mb-3'>{t('Copy')}</button>
                                                    </li>}
                                                    <li>
                                                        { ((editChart._id !== val._id ) && (val.type !== 'Device Chart' && val.type !== 'Electric Bill Chart') && val.width == '1/4') && <button onClick={ () => setEditChart(val) } className='text-[#599bfb] font-semibold mb-2'>
                                                            {t('Edit_Chart')}
                                                        </button>}
                                                    </li>
                                                    <li>
                                                        {userType === 'super-admin' ?
                                                            <Link to={ `/card/new?id=${ ID }&card_id=${ val._id }` } className='text-[#599bfb] font-semibold mb-2'>{t('Settings')}</Link> :
                                                            userPermisiions.includes('Edit Chart') && <Link to={ `/card/new?id=${ ID }&card_id=${ val._id }` } className='text-[#599bfb] font-semibold mb-2'>{t('Settings')}</Link>}
                                                    </li>
                                                    <li>
                                                        {userType === 'super-admin' ? <button onClick={ () => deleteCard(val._id) } className='text-[#ff6262] font-semibold mb-2 mt-2'>{t('Delete')}</button>
                                                            : userPermisiions.includes('Delete Chart') && <button onClick={ () => deleteCard(val._id) } className='text-[#ff6262] font-semibold mb-2 mt-2'>{t('Delete')}</button>}
                                                    </li>
                                                    {(val.type !== 'Device Chart' && val.type !== 'Electric Bill Chart') && <li>
                                                        <button onClick={ () => downloadCSV(val) } className='text-[#5a7bad] font-semibold mb-2'>{t('Download_CSV')}
                                                        </button>
                                                    </li>}
                                                    <li>
                                                        {editChart._id === val._id && <UpdateCard getCards={ getCards } getUpdateCards={ getUpdateCards } dashboard={ dashboard } editChart={ editChart } setDownloadBtnActive={ setDownloadBtnActive } />}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                            <div>
                            </div>
                        </div>}
                        {isMobile && <div className='flex gap-[20px] flex-wrap mt-[10px] '>
                            {cards?.map((val) =>
                                <div key={ val._id }
                                    onMouseEnter={ () => { setEditChart(val) } }
                                    className={ 'flex w-full gap-4 h-fit' }>

                                    <div className='group relative bg-[#f6fbff] w-full p-2 py-3 shadow-[0px_0px_33px_-9px_rgba(0,0,0,.2)] rounded-xl'>
                                        <div className='md:flex items-center gap-6 flex-wrap mb-1'>
                                            <h4 className='text-[18px] text-[#5a7bad] font-bold'>{val.title}</h4>
                                        </div>

                                        {userType === 'super-admin' ?
                                            <>
                                                {val.type === 'Line Chart' && <div> <Linechart toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Bar Chart' && <div> <BarchartDemo toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } refresh={ refresh } id={ val._id } dashboardId = { ID } maxWidth={ val.maxValue }/>  </div> }
                                                {/* {val.type === 'Bar Chart' && <div> <Barchart id={ val._id } dashboardId = { ID } />  </div> } */}
                                                {val.type === 'Gauge Chart' && <div> <GaugechartDemo toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID } maxWidth={ val.maxValue }/> </div>}
                                                {val.type === 'Device Chart' && <div> <DeviceCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Electric Bill Chart' && <div> <ElectricBillCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Status Chart' && <div> <StatusCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                            </>
                                            : userPermisiions.includes('View Chart') && <>
                                                {val.type === 'Line Chart' && <div> <Linechart toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID } /> </div>}
                                                {val.type === 'Bar Chart' && <div> <BarchartDemo toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } refresh={ refresh } id={ val._id } dashboardId = { ID } maxWidth={ val.maxValue }/>  </div> }
                                                {val.type === 'Gauge Chart' && <div> <GaugechartDemo toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID } maxWidth={ val.maxValue }/> </div>}
                                                {val.type === 'Device Chart' && <div> <DeviceCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Electric Bill Chart' && <div> <ElectricBillCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                                {val.type === 'Status Chart' && <div> <StatusCard toDate={ toDate } val={ val } dashboard={ dashboard } setSelectedCards={ setSelectedCards } selectedCards={ selectedCards } selectingCards={ selectingCards } fromDate={ fromDate } key={ refresh } refresh={ refresh } id={ val._id } dashboardId = { ID }/> </div>}
                                            </>}

                                        <div className='md:hidden block my-[20px] ml-auto text-end w-[300px]'>
                                            {/* <div className='mt-[60px]'>
                                        <button onClick={ ()=>setOpen(!open) }>
                                            <RiArrowDownSLine className='text-[20px]'/>
                                        </button>
                                    </div> */}
                                            { open ? <div className='absolute right-0 z-[100] bg-[#fff] w-[150px] p-[10px] items-center text-end justify-end gap-4 mr-[10px]'>
                                                <div className=' items-center'>
                                                    <button className='block ml-auto text-[#5a7bad] pb-[3px] font-semibold '>{t('Settings')}</button>
                                                    <button onClick={ () => downloadCSV(val) } className='ml-auto block text-[#5a7bad] font-semibold '>{t('Download_CSV')}
                                                    </button>

                                                    {userType === 'super-admin' ? <Link to={ `/card/new?id=${ ID }&card_id=${ val._id }` } className='text-[#599bfb] font-medium'>{t('edit')}</Link>
                                                        : userPermisiions.includes('Edit Chart') && <Link to={ `/card/new?id=${ ID }&card_id=${ val._id }` } className='text-[#599bfb] font-medium'>{t('edit')}</Link>}
                                                    {userType === 'super-admin' ? <button onClick={ () => deleteCard(val._id) } className='w-fit ml-auto py-[3px] justify-end flex transition-all text-[#ff6262] font-semibold rounded-md flex justify-end'>{t('Delete')}</button>
                                                        : userPermisiions.includes('Delete Chart') && <button onClick={ () => deleteCard(val._id) } className='  w-fit py-[3px] transition-all text-[#ff6262] font-semibold rounded-md flex mx-auto justify-end'>{t('Delete')}</button>}
                                                </div>
                                            </div> : ''}
                                        </div>
                                        <div className='flex absolute right-[0px] top-[15px]'>
                                            <div className="mr-3 w-full z-5000">
                                                <div className='grid grid-cols-2'>
                                                    <Button variant='primary'
                                                        onClick={ (e) => {
                                                            getOffsetLeft(e.screenX, true)
                                                            downloadBtnActive == val._id ? setDownloadBtnActive('') : setDownloadBtnActive(val._id);
                                                        } }
                                                        className={ (downloadBtnActive === val._id) ? 'hover:!bg-opacity-100' : '' }>

                                                        <FontAwesomeIcon
                                                            icon={ faGear }
                                                            style={ { color: 'gray' } }
                                                            className='-mt-1'
                                                        />
                                                    </Button>
                                                    <button onClick={ () =>  {setZoom(val), setOpenZoom(true)} } className='text-[24px]'>
                                                        <AiOutlineArrowsAlt/>
                                                    </button>
                                                </div>

                                                <ul
                                                    // onMouseLeave={ () => setDownloadBtnActive('') }
                                                    style={ { marginLeft: `-${ offset }px`, zIndex:'100' } }
                                                    className={ cn('absolute top-[calc(100%+0.5rem)] -ml-[100px] w-max h-max bg-white shadow-md rounded-sm text-center p-3', {
                                                        'visible pointer-events-auto': (downloadBtnActive === val._id),
                                                        'invisible pointer-events-none': !(downloadBtnActive === val._id)
                                                    }) }>
                                                    <li className='text-right'>
                                                        <FontAwesomeIcon
                                                            icon={ faClose }
                                                            onClick={ () => { setDownloadBtnActive('') } }
                                                            style={ { color: 'gray' } }
                                                            className={ cn({
                                                                'rotate-180' : !(downloadBtnActive === val._id) ? true : false
                                                            }) }
                                                        />
                                                    </li>
                                                    {userType === 'super-admin' && <li>
                                                        <button onClick={ () =>{ setDashboardata(val); setDownloadBtnActive('') } } className='text-[#fff] text-[14px] rounded-md py-[1px] px-[10px] bg-[#599bfb] font-medium mb-3'>{t('Copy')}</button>
                                                    </li>}
                                                    <li>
                                                        { ((editChart._id !== val._id ) && (val.type !== 'Device Chart' && val.type !== 'Electric Bill Chart') && val.width == '1/4') && <button onClick={ () => setEditChart(val) } className='text-[#599bfb] font-semibold mb-2'>
                                                            {t('Edit_Chart')}
                                                        </button>}
                                                    </li>
                                                    <li>
                                                        {userType === 'super-admin' ?
                                                            <Link to={ `/card/new?id=${ ID }&card_id=${ val._id }` } className='text-[#599bfb] font-semibold mb-2'>{t('Settings')}</Link> :
                                                            userPermisiions.includes('Edit Chart') && <Link to={ `/card/new?id=${ ID }&card_id=${ val._id }` } className='text-[#599bfb] font-semibold mb-2'>{t('Settings')}</Link>}
                                                    </li>
                                                    <li>
                                                        {userType === 'super-admin' ? <button onClick={ () => deleteCard(val._id) } className='text-[#ff6262] font-semibold mb-2 mt-2'>{t('Delete')}</button>
                                                            : userPermisiions.includes('Delete Chart') && <button onClick={ () => deleteCard(val._id) } className='text-[#ff6262] font-semibold mb-2 mt-2'>{t('Delete')}</button>}
                                                    </li>
                                                    {(val.type !== 'Device Chart' && val.type !== 'Electric Bill Chart') && <li>
                                                        <button onClick={ () => downloadCSV(val) } className='text-[#5a7bad] font-semibold mb-2'>{t('Download_CSV')}
                                                        </button>
                                                    </li>}
                                                    <li>
                                                        {editChart._id === val._id && <UpdateCard  getCards={ getCards } dashboard={ dashboard } editChart={ editChart } setDownloadBtnActive={ setDownloadBtnActive } />}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>)}
                            <div>
                            </div>
                        </div>}
                        <Transition.Root show={ open1 } as={ Fragment }>
                            <Dialog as="div" className="relative z-10" onClose={ () => setOpen1(false) }>
                                <Transition.Child
                                    as={ Fragment }
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="fixed inset-0 bg-[#292929] bg-opacity-75 transition-opacity" />
                                </Transition.Child>

                                <div className="fixed inset-0 z-10 overflow-y-auto">
                                    <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                                        <Transition.Child
                                            as={ Fragment }
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        >
                                            <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                                                <div>
                                                    <div className='flex gap-[15px]'>
                                                        <div className='bg-[#599bfb] h-fit p-3 rounded-lg'>
                                                            <CopyIcon />
                                                        </div>
                                                        <div>
                                                            <h5 className='text-[#599bfb] font-medium text-xl'>{t('Copy')} </h5>
                                                            <h5 className='text-[#5a7bad] font-normal'>{t('copy_word')}</h5>
                                                            <div className='w-full relative mt-2'>
                                                                <label htmlFor="User Type" className="block text-sm font-medium text-[#5a7bad]">
                                                                    {t('Dashboard')}
                                                                </label>
                                                                <div className='relative group mt-1'>
                                                                    <input value={ selectValue.name } onClick={ ()=>setShow(!show) } type="text" className="block w-full px-3 py-2 border border-gray-300 rounded-full outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] text-[#5a7bad] sm:text-sm" />
                                                                    {show &&
                                                                    <div className='absolute top-[110%] bg-white py-[5px] border-gray-300 border-[1px] rounded-lg w-full'>
                                                                        {dbData && dbData.map((val, i) =>
                                                                            <div key={ i }>
                                                                                <div className='py-[5px] font-bold text-sm px-[20px] text-[#599bfb] font-medium cursor-pointer hover:bg-slate-100'># {val.site}</div>
                                                                                {val.dashboard?.map((db) =>
                                                                                    <div key={ db._id } onClick={ ()=> handleCopyChart(db) } className='py-[5px] px-[20px] pl-[35px] text-sm text-[#5a7bad] font-medium cursor-pointer hover:bg-slate-100'>{db.name}</div>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='flex justify-end gap-3 mt-6'>
                                                        <button onClick={ ()=> setOpen1(false) } className='px-[15px] py-[5px] font-medium text-[#5a7bad] rounded-lg border-[1px] border-[#5a7bad]'>{t('Cancel')}</button>
                                                        {userType === 'super-admin' && <button onClick={ onclickCopyChart } className='px-[15px] py-[5px] bg-[#5a7bad] font-medium text-[#fff] rounded-lg border-[1px] border-[#5a7bad]'>{t('Copy')}</button>}
                                                    </div>
                                                </div>
                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </div>
                            </Dialog>
                        </Transition.Root>
                    </div>
                </div>
            }

        </div>

    )
}

export default Cards